import { localStorageName } from '@/config/utils';

class TokenService {
  getLocalRefreshToken() {
    const user = JSON.parse(localStorage.getItem(localStorageName('member')));
    return user?.refreshToken;
  }

  getLocalAccessToken() {
    const user = JSON.parse(localStorage.getItem(localStorageName('member')));
    return user?.data.authorisation.token;
  }

  updateLocalAccessToken(token) {
    let user = JSON.parse(localStorage.getItem(localStorageName('member')));
    user.authorisation.token = token;
    localStorage.setItem("member", JSON.stringify(user));
  }

  getUser() {
    return JSON.parse(localStorage.getItem(localStorageName('member')));
  }

  setUser(user) {
    localStorage.setItem(localStorageName('member'), JSON.stringify(user));
  }

  removeUser() {
    localStorage.removeItem(localStorageName('member'));
  }

  setMember(member) {
    localStorage.setItem(localStorageName('member'), JSON.stringify(member));
  }

  getMember() {
    return JSON.parse(localStorage.getItem(localStorageName('member')));
  }
}

export default new TokenService();