<template>
    <div v-if="active" class="loader-wrapper">
      <div class="loader">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'Loader',
    props: {
      active: Boolean,
      text: String
    }
  }
  </script>
  
  <style scoped>
  .loader-wrapper {
    text-align: center;
    z-index: 9999 !important;
  }
  
  .loader {
    display: inline-block;
    width: 80px;
    height: 80px;
    position: absolute;
    top: 40%;
    left: 40%;
    z-index: 9999;
  }
  
  .loader div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 50px;
    height: 50px;
    margin: 8px;
    /* border: 4px solid black; */
    border-radius: 50%;
    /* animation: loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite; */
    /* border-color: black transparent transparent transparent; */
  }
  
  .loader div:nth-child(1) {
    animation-delay: -0.45s;
  }
  
  .loader div:nth-child(2) {
    animation-delay: -0.3s;
  }
  
  .loader div:nth-child(3) {
    animation-delay: -0.15s;
  }
  
  @keyframes loader {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  </style>