import api from './api';

class PromocodeService {
  getListPromocode(dataForm) {
    return api.post('/get-promo-code', dataForm);
  }
  
  getMyReward(dataForm) {
    // return api.get("/member/rewards/my-reward", {
    //   baseURL: process.env.VUE_APP_BASE_MINTY_URL,
    //   headers: {
    //       'Merchantid': dataForm.merchant_id
    //   },
    //   params: {
    //     type: dataForm.type,
    //     search_keyword: dataForm.search_keyword,
    //     memberId: dataForm.memberId,
    //   }
    // });
    return api.post(`${process.env.VUE_APP_BASE_MINTY_URL}/member/rewards/my-reward`, dataForm);
  }

  calculateVoucherPrice(dataForm) {
    return api.post('/calculate-voucher-price', dataForm);
  }

  getListVoucher(dataForm) {
    return api.post('/get-free-vouchers', dataForm);
  }

  exchangeFreeVoucher(dataForm) {
    return api.post('/exchange-free-voucher', dataForm);
  }

  getMyRewardOrderingOnline(dataForm) {
    // return api.get("/member/rewards/my-reward", {
    //   baseURL: process.env.VUE_APP_BASE_MINTY_URL,
    //   headers: {
    //       'Merchantid': dataForm.merchant_id
    //   },
    //   params: {
    //     type: dataForm.type,
    //     search_keyword: dataForm.search_keyword,
    //     memberId: dataForm.memberId,
    //   }
    // });
    return api.post(`${process.env.VUE_APP_BASE_MINTY_URL}/member/rewards/my-reward-for-ordering-online`, dataForm);
  }
}

export default new PromocodeService();
