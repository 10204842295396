<template>
    <div class="page-header align-items-start min-vh-100">
        <span class="mask bg-white"></span>
  
        <div class="container" style="padding-left: 0px;padding-right: 0px;">
            <div class="row">
                <div class="notifications">
                    <material-alert v-if="error" color="danger" icon="ni ni-like-2 ni-lg" dismissible>
                    {{ error }}
                    </material-alert>
                </div>
                <div class="col-lg-4 col-md-8 col-12">
                    <div class="card-body">
                        <div class="pb-2 text-bold-500 d-flex align-items-center">
                            <i class="material-icons-round opacity-10" style="margin-bottom: 2px;" @click="gotoBackOrderPage()">arrow_back_ios</i>
                            <div class="ms-1">Pick Up Point</div>
                        </div>
                        <div style="border-bottom: 1px solid #bdbdbd;"></div>
                        <div class="pt-2" style="display: inline-flex;align-items: center;">
                            <span class="material-icons me-3 color-green md-30">place</span>
                            <div>
                                <div class="text-bold-500">
                                    {{branch.branch_name}}
                                </div>
                                <div style="color: black;font-size: 13px;">
                                    {{ branch.branch_address }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="line"></div>
                    <div class="card-body">
                        <div class="pt-2 pb-3" style="display:inline-flex;align-items:center;gap: 20px;" v-for="(item, index) of dataItem" :key="index">
                            <div style="width: 30%;">
                                <img :src="item.image" style="width: 100%;height: 100px;"/>
                            </div>
                            <div style="width: 70%;">
                                <div style="display: inline-flex;width: 100%;">
                                    <div style="color: black;font-weight: 500;width: 80%;">
                                        {{ item.name }}
                                    </div>
                                    <div style="color: black;font-weight: 500;width: 20%;">
                                        {{ item.price }}
                                    </div>
                                </div>
                                <div style="display: inline-flex;width: 100%;">
                                    <div style="color: black;font-size: 13px;width: 80%;">
                                        with Chilli
                                    </div>
                                    <div>
                                        
                                    </div>
                                </div>
                                <div style="display: inline-flex;width: 100%;" v-for="(itemModifier, indexModifier) of item.modifier" :key="indexModifier">
                                    <div style="color: black;font-size: 13px;width: 80%;">
                                        {{ itemModifier.name }}
                                    </div>
                                    <div style="color: black;width: 20%;">
                                        {{ itemModifier.price }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="border-bottom: 1px solid #bdbdbd;"></div>
                        <div class="pt-3 color-common pb-3" @click="gotoBackOrderPage()">
                            Add more items
                        </div>
                        <img src="https://t3.ftcdn.net/jpg/04/75/78/56/360_F_475785604_HDtTcxBFA0Av87F7JoFmpircCcatQ22b.jpg" style="width: 100%;height: 100px;"/>
                        <div style="width: 100%;" class="pt-2">
                            <div style="display: inline-flex;width: 100%;">
                                <div style="color: black;font-size: 13px;width: 80%;">
                                    Subtotal
                                </div>
                                <div class="text-end">
                                    {{ subTotal }}
                                </div>
                            </div>
                            <div style="display: inline-flex;width: 100%;">
                                <div style="color: black;font-size: 13px;width: 80%;">
                                    GST incl.
                                </div>
                                <div class="text-end">
                                    {{ gstTotal }}
                                </div>
                            </div>
                            <div style="display: inline-flex;width: 100%;">
                                <div style="color: black;font-weight: 700;width: 80%;">
                                    TOTAL
                                </div>
                                <div style="color: black;font-weight: 700;width: 20%;text-align: end;">
                                    {{ total }}
                                </div>
                            </div>
                        </div>

                        <a href="select-promo-code">
                            <div class="mt-2" style="width:100%;text-align:left;border:1px solid #bdbdbd;border-radius:5px;padding: 10px;">
                                {{ $route.params.selectedPromocodeName ? 'APPLIED PROMO CODE : '+$route.params.selectedPromocodeName : 'PROMO CODE' }}
                            </div>
                        </a>
                    </div>

                    <div class="line"></div>

                    <div class="card-body">
                        <div style="display: inline-flex;align-items: center; width: 100%;">
                            <div style="width: 10%;">
                                <input type="radio" :value="cutleryValue" v-model="picked" :checked="cutleryChecked" />
                            </div>
                            <div style="width: 90%;">
                                <div class="text-bold-500">
                                    Cutlery
                                </div>
                                <div style="color: black;font-size: 13px;">
                                    Lorem ipsum dolor sit amet.
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="line"></div>

                    <div class="card-body">
                        <div style="display: inline-flex;align-items: center; width: 100%;">
                            <div style="width:100%;align-items: center;display: grid;">
                                <div class="text-bold-500">
                                    Updated Waiting Time
                                </div>
                                <div style="display:inline-flex;align-items: center;">
                                    <span class="material-icons me-2 color-green md-30">update</span>
                                    <div style="color: black;font-size: 13px;font-weight: 700;">
                                        30 - 45 minutes
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="line"></div>

                    <div class="card-body">
                        <div style="display: inline-flex;align-items: center; width: 100%;">
                            <div style="width: 100%;">
                                <div class="text-bold-500">
                                    Contact Details
                                </div>
                                <input v-model="memberName" type="text" placeholder="NAME" class="mt-2" style="width:100%;text-align:left;border: 1px solid #bdbdbd;border-radius: 5px;height: 40px;padding-left: 10px;" />
                                <span v-if="showErrorName" style="color: red;">Name cannot blank.</span>
                                <input v-model="memberPhone" type="text" placeholder="MOBILE No." class="mt-2" style="width:100%;text-align:left;border: 1px solid #bdbdbd;border-radius: 5px;height: 40px;padding-left: 10px;" />
                                <span v-if="showErrorPhone" style="color: red;">Mobile cannot blank.</span>
                            </div>
                        </div>
                    </div>
                    
                    <div class="line"></div>

                    <div class="card-body">
                        <div style="display: inline-flex;align-items: center; width: 100%;">
                            <div style="width: 100%;" class="text-center">
                                <button
                                    @click="handleProceed()"
                                    class="btn bg-green w-30 text-center"
                                    type="button"
                                >
                                    Proceed
                                </button>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="card-body">
                        <div style="display: inline-flex;align-items: center; width: 100%;">
                            <div style="width: 100%;">
                                <div class="text-bold-500">
                                    Select Payment Method
                                </div>
                                <div style="display: inline-flex;width: 100%;gap: 10px" class="mt-1">
                                    <div :class="paymentMethod == 'Visa' ? 'visaActive' : 'visa'"  @click="selectPaymentMethod('Visa')">
                                        <img src="https://logowik.com/content/uploads/images/visa-payment-card1873.jpg" style="width: 100%;height: 100%;border-radius: 5px;" />
                                    </div>
                                    <div :class="paymentMethod == 'Master' ? 'masterActive' : 'master'" @click="selectPaymentMethod('Master')">
                                        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b7/MasterCard_Logo.svg/2560px-MasterCard_Logo.svg.png" style="width:100%;height:100%;border-radius:5px;padding: 10px;padding-left: 15px;padding-right: 15px;" />
                                    </div>
                                </div>
                                <div style="display: inline-flex;width: 100%;gap: 10px" class="mt-3">
                                    <div :class="paymentMethod == 'GooglePay' ? 'googlePayActive' : 'googlePay'" @click="selectPaymentMethod('GooglePay')">
                                        <img src="https://lh3.googleusercontent.com/NFHyJvxk1le22pFhu_3PbzWkwi1o_CeNyXo-srF0sVTVfwhjMhus-y5vt0k15NoXSrujTCx4BpR--nTJhgqXpGDGrG6gCwMhbb3_lb4A755_34nr1Rfb" style="width:100%;height:100%;border-radius:5px;" />
                                    </div>
                                    <div :class="paymentMethod == 'ApplePay' ? 'applePayActive' : 'applePay'" @click="selectPaymentMethod('ApplePay')">
                                        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b0/Apple_Pay_logo.svg/2560px-Apple_Pay_logo.svg.png" style="width:100%;height:100%;border-radius:5px;padding: 10px;padding-left: 15px;padding-right: 15px;" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->

                    <div style="margin-bottom: 20px;"></div>
                </div>
            </div>
        </div>
        <div class="position-fixed top-1 end-1 z-index-2 message-success">
                <material-snackbar
                    v-if="snackbar === 'danger' || snackbar === 'success'"
                    title=""
                    date=""
                    :description="message"
                    :icon="{ component: 'campaign', color: 'white' }"
                    :color="snackbar"
                    :close-handler="closeSnackbar"
                />
            </div>
        <material-loading :active="loaderActive" />
    </div>
  </template>
  
<script>
    import MaterialAlert from "@/components/MaterialAlert.vue";
    import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
    import MaterialLoading from '@/components/MaterialLoading.vue';
    import OrderService from "../services/order.service";
  
    export default {
        name: "sign-in",
        components: {
            MaterialAlert,
            MaterialSnackbar,
            MaterialLoading
        },
        data() {
            return {
                message: '',
                loaderActive: false,
                snackbar: null,
                error: '',
                paymentMethod: '',
                branch: JSON.parse(localStorage.getItem('BRANCH_INFO', '')),
                cutleryValue: 1,
                cutleryChecked: true,
                memberInfo: JSON.parse(localStorage.getItem("memberInfo", '')),
                memberPhone: null,
                memberName: null,
                showErrorName: false,
                showErrorPhone: false,
                subTotal: 's$40.00',
                gstTotal: 's$0.00',
                total: 's$40.00',
                totalToPay: 40,
                dataItem: [
                    {
                        id: 1,
                        image: 'https://cdn.tgdd.vn/2021/04/CookProduct/1200-1200x676-49.jpg',
                        name: 'Egg Fried Rice with Seasoned Prawns 1',
                        price: 's$9.00',
                        quantity: 1,
                        modifier: [
                            {
                                id: 1,
                                name: 'Tobiko 1',
                                price: 's$4.00',
                                quantity: 1
                            },
                            {
                                id: 2,
                                name: 'Broccoli 1',
                                price: 's$6.00',
                                quantity: 1
                            }
                        ]
                    },
                    {
                        id: 2,
                        image: 'https://cdn.tgdd.vn/2021/04/CookProduct/1200-1200x676-49.jpg',
                        name: 'Egg Fried Rice with Seasoned Prawns 2',
                        price: 's$9.00',
                        quantity: 1,
                        modifier: [
                            {
                                id: 3,
                                name: 'Tobiko 3',
                                price: 's$4.00',
                                quantity: 1
                            },
                            {
                                id: 4,
                                name: 'Broccoli 4',
                                price: 's$8.00',
                                quantity: 1
                            }
                        ]
                    }
                ]
            };
        },
        mounted() {
            const merchantCode = localStorage.getItem("MERCHANT_CODE", "");
            const branchCode = localStorage.getItem('BRANCH_CODE', '');
            
            //load after payment done
            var session_id = this.$route.query.session_id;
            var online_order_id = this.$route.query.online_order_id;
            var price = this.$route.query.price ?? 0;
            if(session_id && online_order_id) { // call back after pay success - stripe
                this.loaderActive = true;
                var dataForm = {
                    session_id: session_id,
                    online_order_id: online_order_id,
                    price: price
                }
                OrderService.saveTransactionByOrder(dataForm).then(
                    (response) => {
                        if (response.data.result.isSuccess) {
                            var url = location.protocol + '//' + location.host+"/"+merchantCode+"/"+branchCode+"/payment-success";
                            window.location.href = url;
                            // this.snackbar = "success";
                            // this.message = "Order Successfully!";
                            // this.setTimeOutNotification();
                        } else {
                            this.snackbar = "danger";
                            this.message = response.data.result.message;
                            this.setTimeOutNotification();
                        }
                        this.loaderActive = false;
                    },
                    (error) => {
                        this.snackbar = "danger";
                        this.message = error;
                        this.loaderActive = false;
                    }
                );
            }
        },
        created() {
            var memberInfoData = JSON.parse(localStorage.getItem("memberInfo", ''));
            this.memberName = memberInfoData.name;
            this.memberPhone = "+"+memberInfoData.phone_code+memberInfoData.phone_number;
        },
        methods: {
            closeSnackbar() {
                this.snackbar = null;
            },
            selectPaymentMethod(paymentMethod) {
                this.paymentMethod = paymentMethod;
            },
            gotoBackOrderPage() {
                this.$router.push('order')
            },
            handleProceed() {
                if(!this.memberName) {
                    this.showErrorName = true;
                } else {
                    this.showErrorName = false;
                }
                if(!this.memberPhone) {
                    this.showErrorPhone = true;
                } else {
                    this.showErrorPhone = false;
                }
                if(this.memberName && this.memberPhone) {
                    this.showLoader();
                    let dataForm = {
                        branch_code: this.branch.branch_code,
                        items: this.dataItem,
                        promocode: this.$route.params.selectedPromocodeId,
                        isCutlery: this.cutleryValue,
                        contact_email: this.memberName,
                        contact_phone: this.memberPhone,
                    };

                    OrderService.saveOrder(dataForm).then(
                        (response) => {
                        if (response.data.result.isSuccess) {
                            console.log('response.data.data', response.data.data);
                            this.processPaymentStripe(response.data.data.id);
                            // this.snackbar = "success";
                            // this.message = "Order Successfully!";
                            // this.setTimeOutNotification();
                        } else {
                            this.snackbar = "danger";
                            this.message = response.data.result.message;
                            this.setTimeOutNotification();
                        }
                            this.isLoadDone = true;
                            this.hideLoader();
                        },
                        (error) => {
                            this.snackbar = "danger";
                            this.message = error;
                            this.hideLoader();
                        }
                    );
                }
            },
            processPaymentStripe(online_order_id) {
                this.showLoader();
                const merchantCode = localStorage.getItem('MERCHANT_CODE', '');
                const branchCode = localStorage.getItem('BRANCH_CODE', '');
                var url = location.protocol + '//' + location.host+"/"+merchantCode+"/"+branchCode+"/check-out";
                var dataSend = {
                    success_url: url+"?session_id={CHECKOUT_SESSION_ID}&online_order_id="+online_order_id+"&price="+this.totalToPay,
                    cancel_url: url,
                    images: "",
                    name: "Order Items",
                    unit_amount: this.totalToPay,
                    quantity: 1
                };
                OrderService.checkoutSessionStripe(dataSend).then(
                    (response) => {
                    if (response.data.result.isSuccess) {
                        window.location.href = response.data.data.url
                    } else {
                        this.loaderActive = false;
                    }
                    },
                    (error) => {
                        console.log(error);
                        this.loaderActive = false;
                    }
                );
            },
            showLoader() {
                this.loaderActive = true;
            },

            hideLoader() {
                this.loaderActive = false;
            },
            setTimeOutNotification() {
                setTimeout(() => {
                    this.snackbar = null
                }, 3000)
            },
        },
    };
</script>
<style>
    input::placeholder {
        font-size: 15px;
        text-align: left;
        font-weight: 600;
        padding-left: 10px;
    }
    .visa {
        width:50%;text-align:left;border: 1px solid #bdbdbd;border-radius: 5px;height: 80px;
    }
    .visaActive {
        width:50%;text-align:left;border: 5px solid #96B7D8;border-radius: 5px;height: 80px;
    }
    .master {
        width:50%;text-align:left;border: 1px solid #bdbdbd;border-radius: 5px;height: 80px;
    }
    .masterActive {
        width:50%;text-align:left;border: 5px solid #96B7D8;border-radius: 5px;height: 80px;
    }
    .googlePay {
        width:50%;text-align:left;border: 1px solid #bdbdbd;border-radius: 5px;height: 80px;
    }
    .googlePayActive {
        width:50%;text-align:left;border: 5px solid #96B7D8;border-radius: 5px;height: 80px;
    }
    .applePay {
        width:50%;text-align:left;border: 1px solid #bdbdbd;border-radius: 5px;height: 80px;
    }
    .applePayActive {
        width:50%;text-align:left;border: 5px solid #96B7D8;border-radius: 5px;height: 80px;
    }
    .text-bold-500 {
        color: black;font-weight: 500;
    }
    .line {
        width: 100%; height: 5px;background:#bdbdbd;
    }
    .text-end {
        color:black;width:20%;text-align: end;
    }
</style>
  