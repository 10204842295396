<template>
  <div class="container-custom" :style="styleObject">
    <header>
      <Header></Header>
    </header>
    <div class="content-custom">
      <div style="height: 100%">
        <div class="content" style="padding-bottom: 30px">
          <div class="i-search">
            <input
              v-model="searchKeyword"
              class="nosubmit"
              type="search"
              id="keyword_search"
              extraClass
              name="keyword_search"
              placeholder="Search for your categories"
              @input="getListCategory"
            />
          </div>
          <div>
            <div class="banner" v-if="slidesHomePage && slidesHomePage.length > 1">
              <swiper
                :slides-per-view="1"
                :space-between="0"
                :loop="true"
                :autoplay="{
                  delay: 3000,
                  disableOnInteraction: false,
                }"
                :initialSlide="0"
              >
                <swiper-slide
                  v-for="(value, index) in slidesHomePage"
                  :key="index"
                >
                  <div
                    class="slider"
                    @click="clickLink(value.destination_link)"
                  >
                    <img :src="value.file_url" alt="error" />
                  </div>
                </swiper-slide>
              </swiper>
            </div>
            <div class="banner" v-else>
              <swiper
                :slides-per-view="1"
                :space-between="0"
                :autoplay="{
                  delay: 3000,
                  disableOnInteraction: false,
                }"
              >
                <swiper-slide
                  v-for="(value, index) in slidesHomePage"
                  :key="index"
                >
                  <div
                    class="slider"
                    @click="clickLink(value.destination_link)"
                  >
                    <img :src="value.file_url" alt="error" />
                  </div>
                </swiper-slide>
              </swiper>
            </div>
            <div>
              <div class="grid">
                <div
                  class="row ms-0"
                  style="
                    padding: 0 20px;
                    margin-right: 0px !important;
                  "
                >
                  <div
                    class="col-6 mt-3 p-0"
                    :class="[index % 2 ? 'ps-2' : 'pe-2']"
                    v-for="(item, index) in categories"
                    :key="index"
                    @click="gotoOrder(item.id)"
                  >
                    <div class="box-category">
                      <div class="c-img">
                        <img
                          :src="item.img_version"
                          alt="error"
                        />
                      </div>
                      <div class="c-name mt-2">
                        <span style="font-weight: bold">{{
                          item.second_name
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div
      v-if="
        isLogined && checkHideCollectVoucherBox == 0 && showCollectNewVoucher
      "
      style="
        width: 100%;
        height: auto;
        padding: 5px;
        align-items: center;
        display: grid;
        justify-content: center;
        background-color: white;
      "
    >
      <div style="font-weight: 800">Collect new voucher!</div>
      <div
        style="
          align-items: center;
          display: flex;
          width: 100%;
          height: auto;
          justify-content: center;
        "
      >
        <img
          @click="openPopupReward"
          src="../assets/img/collect-reward.png"
          style="width: 48px; margin-right: 5px"
          alt="user1"
        />
        <i
          @click="hideCollectVoucherBox"
          class="material-icons-round opacity-10"
          >close</i
        >
      </div>
    </div> -->
    <footer>
      <Footer></Footer>
      <!-- <VoucherAvailable :isLoadFromCategoryPage="1"></VoucherAvailable> -->
    </footer>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, { Pagination, Autoplay } from "swiper";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import Header from "@/common/Header/OrderHeader";
import OrderService from "../services/order.service";
import $ from "jquery";
import "bootstrap/dist/js/bootstrap.bundle";
import "bootstrap/js/dist/modal";
import Footer from "@/common/Footer/OrderFooter";
// import VoucherAvailable from "@/common/VoucherAvailable/VoucherAvailable";
import EventBus from "vue3-eventbus";
import { localStorageName } from '@/config/utils';

SwiperCore.use([Pagination, Autoplay]);

export default {
  name: "Order",
  components: {
    Swiper,
    SwiperSlide,
    Header,
    Footer,
    // VoucherAvailable,
  },
  data() {
    return {
      merchantCode: "",
      branchCode: "",
      pathType: "",
      tableCode: "",
      branch: JSON.parse(localStorage.getItem(localStorageName('BRANCH_INFO'), '')),
      categories: [],
      categorySelect: {},
      groups: [],
      groupSelect: {},
      tagName: "",
      itemGroup: [],
      searchKeyword: "",
      searchTimerId: null,
      itemSearch: [],
      checkHideCollectVoucherBox: localStorage.getItem(
        localStorageName('HIDE-COLLECT-VOUCHER-BOX')
      )
        ? localStorage.getItem(localStorageName('HIDE-COLLECT-VOUCHER-BOX'))
        : 0,
      slidesHomePage: [],
      showCollectNewVoucher: false,
      memberInfo: JSON.parse(localStorage.getItem(localStorageName('memberInfo'), '')),
      isLogined: false,
      tableInfo: {},
    };
  },
  created() {
    if (this.memberInfo) {
      this.isLogined = true;
    }
  },
  mounted() {
    const memorize = this.$route.query.memorize ?? null;
    if (memorize) {
      $(".bottom_modal_memorize").click();
    }

    this.merchantCode = this.$store.state.merchantCode;
    this.branchCode = this.$store.state.branchCode;
    this.pathType = this.$route.params.type;
    this.tableCode = this.$route.params.table_code;

    this.tableInfo = JSON.parse(localStorage.getItem('table_' + this.merchantCode.toLowerCase() + '_' + this.branchCode.toLowerCase() + '_' + this.tableCode.toLowerCase(), {}));

    if (this.merchantCode && this.branchCode) {
      this.getListCategory();
      this.getSlidesHomePage();
    }
    EventBus.on("showCollectNewVoucher", () => {
      setTimeout(() => {
        this.showCollectNewVoucher = true;
      }, 300);
    });
  },
  computed: {
    styleObject() {
      return {
        "--color": this.$store.state.color,
      };
    },
  },
  methods: {
    getListCategory() {
      this.showLoader();
      let dataForm = {
        menuset_id: this.branch.menuset_id ?? 0,
        search_keyword: this.searchKeyword,
        branch_code: this.branch.branch_code ?? ''
      };

      OrderService.getListCategory(dataForm).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.categories = response.data.data;
            if (this.categories.length) {
              this.categorySelect = this.categories[0];
              this.itemGroup.push({
                tag_name: this.categorySelect.second_name,
                items: this.categorySelect.items,
              });
            }
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.isLoadDone = true;
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
        }
      );
    },

    onChangesearchKeyword(event) {
      this.searchKeyword = event.target.value;
      clearTimeout(this.searchTimerId);
      this.searchTimerId = setTimeout(() => {
        this.searchItem();
      }, 1000);
    },

    searchItem() {
      this.showLoader();
      let dataForm = {
        menuset_id: this.branch.menuset_id ?? 0,
        search_keyword: this.searchKeyword,
      };

      OrderService.searchItem(dataForm).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.itemSearch = response.data.data;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.isLoadDone = true;
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
        }
      );
    },

    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },

    showLoader() {
      this.loaderActive = true;
    },

    hideLoader() {
      this.loaderActive = false;
    },
    gotoOrder(id = 0) {
      this.$router.push({ name: "Order", params: {
        merchant_code: this.merchantCode,
        type: this.pathType,
        branch_code: this.branchCode,
        table_code: this.tableCode,
        category_id: id 
      } });
    },
    // gotoMyReward() {
    //   this.$router.push({ name: "MyReward", params: {
    //     merchant_code: this.merchantCode,
    //     type: this.pathType,
    //     branch_code: this.branchCode,
    //     table_code: this.tableCode
    //   } });
    // },
    // openPopupReward() {
    //   $(".btn-show-voucher-available-modal").click();
    // },
    hideCollectVoucherBox() {
      localStorage.setItem(localStorageName('HIDE-COLLECT-VOUCHER-BOX'), 1);
      location.reload();
    },
    getSlidesHomePage() {
      this.showLoader();
      var dataForm = {
        isMintyApi: true,
        branch_code: this.branchCode,
        where_showing: 1,
        is_ordering_online: 1,
      };
      OrderService.getSlidesHomePage(dataForm).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.slidesHomePage = response.data.data;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.isLoadDone = true;
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
        }
      );
    },

    clickLink(link) {
      if (link && link != "") {
        window.open(link);
      }
    },
  },
};
</script>

<style scoped>
.page-header {
  position: relative;
  background: #ffffff;
  padding-bottom: 0 !important;
}

.page-header .container {
  padding: 0 !important;
}

.page-header .container .content {
  /* margin-top: 65px; */
  margin-bottom: 25px;
}

.i-search {
  padding-left: 24px !important;
  padding-right: 24px !important;
  background: var(--color);
  padding-bottom: 5px;
}

input[type="search"] {
  border: none;
  background: transparent;
  margin: 0;
  padding: 7px 8px;
  font-size: 14px;
  color: inherit;
  border: 1px solid transparent;
  border-radius: inherit;
  background-color: #ffffff !important;
  border-radius: 50px;
  text-align: left !important;
}

input[type="search"]::placeholder {
  color: #bbb;
  text-align: left;
  font-weight: normal;
  font-size: 13px;
}

input[type="search"]:focus {
  outline: none;
  border: none;
}

input.nosubmit {
  width: 100%;
  height: 32px;
  padding: 5px 4px 5px 40px;
  background: transparent
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E")
    no-repeat 13px center;
  margin-top: 5px;
}

.banner {
  position: relative;
}

.material-icons-round.active {
  color: var(--color);
}

.box-category {
  width: 100%;
  height: 100%;
  padding-top: 10px;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
}

.box-category .c-img {
  display: flex;
  justify-content: center;
}

.box-category .c-img img {
  width: 100%;
  max-width: 175px;
  height: auto;
  max-height: 175px;
  border-radius: 10px;
}

.container-custom {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content-custom {
  flex: 1;
  background-color: white;
  overflow: auto;
}

footer {
  background-color: white;
  padding-top: 10px;
}

/* webkit solution */
::-webkit-input-placeholder {
  text-align:left !important;
}
/* mozilla solution */
input:-moz-placeholder {
  text-align:left !important;
}
</style>
