<template>
    <div :style="styleObject">
        <button
            style="display: none"
            type="button"
            class="btn-show-voucher-available-modal"
            data-bs-toggle="modal"
            data-bs-target="#modalShowVouchersAvailable"
        ></button>
        <div
            class="modal fade"
            id="modalShowVouchersAvailable"
            tabindex="-1"
            aria-labelledby="modalShowVouchersAvailable"
            aria-hidden="true"
            style="padding-left: 10px;padding-right: 10px;"
        >
            <div class="modal-dialog modal-dialog-centered" style="width:100%;margin: 0px;">
                <div class="modal-content" style="background: unset;border: 0px;">
                    <div class="modal-body" style="padding: 0px;height: 90%;">
                        <div style="width: 100%; height: 400px;">
                            <div style="height:65%;width:100%;">
                                <div style="width: 100%; height: 10%;"></div>
                                <div style="width:100%;height:90%;background-color:#FC8B25;border-top-left-radius:5px;border-top-right-radius:5px;padding:10px;overflow-y: auto;">
                                    <div v-if="dataList && dataList.length">
                                        <div v-for="(item, index) in dataList" :key="index" style="width:100%;height:80px;background:white;padding:5px;display:inline-flex;border-radius: 5px;margin-bottom: 10px">
                                            <div style="width:30%;height:100%;border-right: 2px dashed var(--color);padding-right: 5px;">
                                                <img :src="item.icon_url" style="width: 100%;height: 100%;" alt="error" />
                                            </div>
                                            <div style="width:70%;padding-left: 10px;display:inline-flex;">
                                                <div style="width:80%;height: 100%;align-items: center;display: grid;">
                                                    <div style="font-size: 13.5px;font-weight: 800;">{{ item.reward_title }}</div>
                                                    <div style="font-size: 11px;color: gray;">{{ item.expired_after }}</div>
                                                </div>
                                                <div style="width:20%;align-items: center;justify-content: center;display: flex;">
                                                    <input type="checkbox" style="width: 20px;height: 20px;" :checked="isSelected(item.id)" @change="handleRadioChange(item.id)" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else style="color:white;text-align: center;font-size: 20px;font-weight: 600;">Voucher Not Available Anymore!</div>
                                </div>
                            </div>
                            <div style="height:35%;width:100%;background-color:#CF0012;border-bottom-left-radius:5px;border-bottom-right-radius:5px;box-shadow:0 3px 10px rgb(0 0 0 / 2);text-align: center;align-items: center;display: grid;justify-content: center;">
                                <div style="color: white;">WE ARE GIVING YOU VOUCHER</div>
                                <div @click="claimNow()" style="align-self: center;background: #FFF0A1;border-radius: 10px;padding: 10px;color: black;font-size: 20px;font-weight: 700;">Claim All!</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="position-fixed top-1 end-1 z-index-2 message-success">
            <material-snackbar
            v-if="snackbar === 'danger' || snackbar === 'success'"
            title=""
            date=""
            :description="message"
            :icon="{ component: 'campaign', color: 'white' }"
            :color="snackbar"
            :close-handler="closeSnackbar"
            />
        </div>
    <material-loading :active="loaderActive" />
</template>
  
<script>
    // import $ from "jquery";
    import PromocodeService from "../../services/promocode.service";
    // import MaterialAlert from "@/components/MaterialAlert.vue";
    import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
    import MaterialLoading from "@/components/MaterialLoading.vue";
    import $ from "jquery";
    import EventBus from "vue3-eventbus";
    import { localStorageName } from '@/config/utils';

    export default {
        name: "VoucherAvailable",
        props: {
            isLoadFromCategoryPage: {
                type: String,
                required: false,
                default: ""
            }
        },
        components: {
            // MaterialAlert,
            MaterialSnackbar,
            MaterialLoading,
        },
        data() {
            return {
                merchantCode: "",
                branchCode: "",
                branch: JSON.parse(localStorage.getItem(localStorageName('BRANCH_INFO'), '')),
                memberInfo: JSON.parse(localStorage.getItem(localStorageName('memberInfo'), '')),
                snackbar: null,
                message: '',
                loaderActive: false,
                dataList: [],
                selectedItems: [],
            };
        },
        created() {
            this.merchantCode = this.$store.state.merchantCode;
            this.branchCode = this.$store.state.branchCode;
            this.getList();
        },
        mounted() {
            // $(".btn-show-voucher-available-modal").click();
        },
        computed: {
            styleObject() {
                return {
                    "--color": this.$store.state.color,
                };
            },
        },
        methods: {
            closeSnackbar() {
                this.snackbar = null;
            },
            setTimeOutNotification() {
                setTimeout(() => {
                    this.snackbar = null;
                }, 3000);
            },
            showLoader() {
                this.loaderActive = true;
            },
            hideLoader() {
                this.loaderActive = false;
            },
            getList() {
                if(this.memberInfo) {
                    // this.showLoader();
                    let dataForm = {
                        member_id: this.memberInfo && this.memberInfo.id ? this.memberInfo.id : 0,
                        branch_code: this.branchCode
                    };

                    PromocodeService.getListVoucher(dataForm).then(
                        (response) => {
                            if (response.data.result.isSuccess) {
                                if(response.data.data && response.data.data.length > 0) {
                                    if(this.isLoadFromCategoryPage != "") {
                                        $(".btn-show-voucher-available-modal").click();
                                        EventBus.emit("showCollectNewVoucher");
                                    }
                                }
                                this.dataList = response.data.data;
                            } else {
                                this.snackbar = "danger";
                                this.message = response.data.result.message;
                                this.setTimeOutNotification();
                            }
                            this.hideLoader();
                        },
                        (error) => {
                            this.snackbar = "danger";
                            this.message = error;
                            this.hideLoader();
                        }
                    );
                }
            },
            isSelected(itemId) {
                return this.selectedItems.includes(itemId);
            },
            handleRadioChange(itemId) {
                if (this.isSelected(itemId)) {
                    this.selectedItems = this.selectedItems.filter(id => id !== itemId);
                } else {
                    this.selectedItems.push(itemId);
                }
            },
            claimNow() {
                // console.log(this.selectedItems);
                if(this.memberInfo && this.selectedItems && this.selectedItems.length > 0) {
                    this.showLoader();
                    let dataForm = {
                        member_id: this.memberInfo && this.memberInfo.id ? this.memberInfo.id : 0,
                        branch_code: this.branchCode,
                        reward_id: this.selectedItems
                    };

                    PromocodeService.exchangeFreeVoucher(dataForm).then(
                        (response) => {
                            if (response.data.result.isSuccess) {
                                location.reload();
                            } else {
                                this.snackbar = "danger";
                                this.message = response.data.result.message;
                                this.setTimeOutNotification();
                            }
                            this.hideLoader();
                        },
                        (error) => {
                            this.snackbar = "danger";
                            this.message = error;
                            this.hideLoader();
                        }
                    );
                } else {
                    this.snackbar = "danger";
                    this.message = "Please select voucher!"
                    this.setTimeOutNotification();
                }
            }
        },
    };
</script>
  
<style scoped>
    
</style>
  