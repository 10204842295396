import api from "./api";
import TokenService from "./token.service";

class AuthService {
  login({ email, password }) {
    return api
      .post("/admin/login", {
        email,
        password
      })
      .then((response) => {
        if (response.data.result.isSuccess) {
          TokenService.setUser(response.data);
        }
        return response.data;
      });
  }

  logout() {
    TokenService.removeUser();
  }

  register({ username, email, password }) {
    return api.post("/auth/signup", {
      username,
      email,
      password
    });
  }

  member_login({ phone_code, phone_number, password }) {
    return api
      .post("member/login", {
        phone_code,
        phone_number,
        password
      })
      .then((response) => {
        if (response.data.result.isSuccess) {
          TokenService.setMember(response.data);
        }
        return response.data;
      });
  }

  loginToken(token) {
    return api
      .post("/login-token", {
        token: token
      })
      .then((response) => {
        if (response.data.result.isSuccess) {
          // TokenService.setMember(response.data);
        }
        return response.data;
      });
  }

  loginByPhoneAndPasscode({ passcode, phone_number }) {
    return api
      .post("/login-by-phone-and-passcode", {
        passcode,
        phone_number
      })
      .then((response) => {
        if (response.data.result.isSuccess) {
          TokenService.setMember(response.data);
        }
        return response.data;
      });
  }

  sendOneTimePasscode({ phone_number }) {
    return api
      .post("/get-one-time-passcode", {
        phone_number
      })
      .then((response) => {
        // if (response.data.result.isSuccess) {
          
        // }
        return response.data;
      });
  }
  
  sendResetToInitialPasscode({ phone_number }) {
    return api
      .post("/send-reset-to-initial-passcode", {
        phone_number
      })
      .then((response) => {
        // if (response.data.result.isSuccess) {
          
        // }
        return response.data;
      });
  }

  changePasscode({ passcode, phone_number }) {
    return api
      .post("/change-passcode", {
        phone_number,
        passcode
      })
      .then((response) => {
        // if (response.data.result.isSuccess) {
          
        // }
        return response.data;
      });
  }

  getMemberInfo({ memberId }) {
    return api
      .post("/get-member-info", {
        memberId
      })
      .then((response) => {
        // if (response.data.result.isSuccess) {
          
        // }
        return response.data;
      });
  }
}

export default new AuthService();
