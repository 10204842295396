import { createStore } from "vuex";
import { auth } from "./auth.module";

export default createStore({
  state: {
    hideConfigButton: false,
    isPinned: true,
    showConfig: false,
    sidebarType: "bg-gradient-dark",
    isRTL: false,
    color: "#96b7d8",
    isNavFixed: false,
    isAbsolute: false,
    showNavs: true,
    showSidenav: true,
    showNavbar: true,
    showFooter: true,
    showMain: true,
    isDarkMode: false,
    navbarFixed:
      "position-sticky blur shadow-blur left-auto top-1 z-index-sticky px-0 mx-4",
    absolute: "position-absolute px-4 mx-0 w-100 z-index-2",
    cartName: "",
    prefixUrl: "",
    merchantCode: "",
    branchCode: "",
    error: {}
  },
  modules: {
    auth,
  },
  mutations: {
    toggleConfigurator(state) {
      state.showConfig = !state.showConfig;
    },
    navbarMinimize(state) {
      const sidenav_show = document.querySelector(".g-sidenav-show");

      if (sidenav_show.classList.contains("g-sidenav-pinned")) {
        sidenav_show.classList.remove("g-sidenav-pinned");
        state.isPinned = true;
      } else {
        sidenav_show.classList.add("g-sidenav-pinned");
        state.isPinned = false;
      }
    },
    navbarFixed(state) {
      if (state.isNavFixed === false) {
        state.isNavFixed = true;
      } else {
        state.isNavFixed = false;
      }
    },
    toggleEveryDisplay(state) {
      state.showNavbar = !state.showNavbar;
      state.showSidenav = !state.showSidenav;
      state.showFooter = !state.showFooter;
    },
    toggleHideConfig(state) {
      state.hideConfigButton = !state.hideConfigButton;
    },
    color(state, payload) {
      state.color = payload;
    },
    prefixUrl(state, payload) {
      state.prefixUrl = payload;
    },
    merchantCode(state, payload) {
      state.merchantCode = payload;
    },
    branchCode(state, payload) {
      state.branchCode = payload;
    },
    error(state, payload) {
      state.error = payload;
    }
  },
  actions: {
    setColor({ commit }, payload) {
      commit("color", payload);
    },
    cartName({ state }) {
      return 'ORDER_INFO_' + state.merchantCode + '_' + state.branchCode;
    },
    prefixUrl({ commit, state }, payload) {
      let merchantCode = state.merchantCode;
      merchantCode = merchantCode ? `/${merchantCode}` : "";
      let branchCode = state.branchCode;
      branchCode = branchCode ? `/${branchCode}` : "";
      let type = payload.type ?? '';
      type = type ? `/${type}` : "";
      let tableCode = payload.table_code ?? '';
      tableCode = tableCode ? `/${tableCode}` : "";
      let prefixUrl = merchantCode + type + branchCode + tableCode;

      commit("prefixUrl", prefixUrl);

      return prefixUrl;
    },
    setMerchantCode({ commit }, payload) {
      commit("merchantCode", payload);
    },
    setBranchCode({ commit }, payload) {
      commit("branchCode", payload);
    },
    setError({ commit }, payload) {
      commit("error", payload)
    }
  },
  getters: {},
});
