<template>
  <header class="order-header" :style="styleObject">
    <div
      class="d-flex justify-content-between align-items-center"
    >
      <div class="d-flex align-items-center">
        <div>
          <i class="material-icons-round opacity-10">location_on</i>
        </div>
        <div class="ms-2 d-flex align-items-center">
          <div class="outlet-name mb-1">{{ branch.branch_name + (tableInfo?.table_no ? (' - Table: ' + tableInfo?.table_no) : '') }}</div>
        </div>
      </div>
      <div v-if="branch.stop_ordering">
        <img src="../../assets/img/closed.png" width="50" height="50">
      </div>
    </div>
  </header>
</template>

<script>
import { localStorageName } from '@/config/utils';

export default {
  name: "OrderHeader",
  components: {},
  data() {
    return {
      branch: {},
      tableInfo: {},
    };
  },
  created() {
    const merchantCode = this.$store.state.merchantCode;
    const branchCode = this.$store.state.branchCode;
    const tableCode = this.$route.params.table_code;
  
    this.branch = JSON.parse(localStorage.getItem(localStorageName('BRANCH_INFO'), ''))
    this.tableInfo = JSON.parse(localStorage.getItem('table_' + merchantCode.toLowerCase() + '_' + branchCode.toLowerCase() + '_' + tableCode.toLowerCase(), {}));
  },
  computed: {
    styleObject() {
      return {
        "--color": this.$store.state.color,
      };
    },
  },
  methods: {
    gotoAddress() {
      if (this.branch.destination_link) {
        window.open(this.branch.destination_link, '_blank');
      }
    }
  },
};
</script>

<style scoped>
header {
  background: var(--color);
  z-index: 99999;
  width: 100%;
  height: 100%;
}

header > div {
  padding: 10px 24px 0 24px;
}

header .material-icons-round {
  font-size: 32px;
}

header .outlet-name {
  font-size: 16px;
  font-weight: bold;
}
</style>
