<template>
  <div :style="styleObject">
    <button
      style="display: none"
      type="button"
      class="btn-notification-out-stock-items-modal"
      data-bs-toggle="modal"
      data-bs-target="#modalNotificationOutStockItems"
    ></button>
    <div
      class="modal fade"
      id="modalNotificationOutStockItems"
      tabindex="-1"
      aria-labelledby="modalNotificationLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div
            class="modal-header d-flex justify-content-end"
            style="border: 0; padding: 0.1rem 1rem"
          >
            <div
              style="display: none"
              class="btn-close-out-stock-items"
              data-bs-dismiss="modal"
            >
              &times;
            </div>
          </div>
          <div class="modal-body text-center">
            <div class="text-title" style="text-align: left">
              These items become 'out of stock' and will be removed from your
              cart.
            </div>

            <div
              class="div-order-items"
              v-for="(item, index) in itemSelect"
              :key="index"
              style="align-items: center; display: block; margin-top: 15px"
            >
              <div
                style="width: 100%; align-items: center"
                v-if="
                  item.isCanGet == 1 && item.quantity - item.stock_balance > 0
                "
              >
                <div class="boxEditQuantity" style="width: 100%; height: auto">
                  <div
                    class="boxEditQuantity"
                    style="text-align: left; height: 100%"
                  >
                    {{ item.quantity - item.stock_balance }} x
                    {{ item.item_name }}
                  </div>
                </div>
              </div>
              <div
                v-for="(
                  modifierOption, indexModifierOption
                ) in item.modifier_group"
                :key="indexModifierOption"
                style="text-align: left"
              >
                <div
                  style="position: relative; display: grid"
                  class="div-items"
                  v-for="(
                    itemModifierOption, indexItemModifierOption
                  ) in modifierOption.items"
                  :key="indexItemModifierOption"
                >
                  <!-- <div class="div-items" v-for="(itemQuantity, indexQuantity) in itemModifierOption.quantity" :key="indexQuantity"> -->
                  <div
                    v-if="itemModifierOption.isCanGet == 1"
                    class="item-name"
                    style="width: 100%"
                  >
                    <span
                      v-if="
                        modifierOption.min_qty == 1 &&
                        modifierOption.max_qty == 1
                      "
                      >{{ itemModifierOption.quantity * item.quantity }} x
                      {{ itemModifierOption.item_name }} together with Main :
                      {{ item.item_name }}</span
                    >
                    <span
                      v-else-if="
                        modifierOption.min_qty > 1 &&
                        modifierOption.max_qty > 1 &&
                        modifierOption.min_qty == modifierOption.max_qty
                      "
                      >{{ itemModifierOption.quantity * item.quantity }} x
                      {{ itemModifierOption.item_name }} together with Main :
                      {{ item.item_name }}</span
                    >
                    <span
                      v-else-if="
                        modifierOption.min_qty >= 1 &&
                        modifierOption.min_qty < modifierOption.max_qty
                      "
                      >{{ itemModifierOption.quantity * item.quantity }} x
                      {{ itemModifierOption.item_name }} together with Main :
                      {{ item.item_name }}</span
                    >
                    <span v-else
                      >{{
                        itemModifierOption.quantity * item.quantity -
                        itemModifierOption.stock_balance
                      }}
                      x {{ itemModifierOption.item_name }} from Main :
                      {{ item.item_name }}</span
                    >
                  </div>
                  <!-- </div> -->
                </div>
              </div>
            </div>

            <button
              @click="removeItemOutStock()"
              type="button"
              class="btn-close-modal mt-3"
              style="margin-top: 30px !important"
            >
              Ok
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from "vue3-eventbus";
import $ from "jquery";

export default {
  name: "ExchangeStatus",
  props: {
    message: {
      type: String,
      default: "",
    },
    itemSelect: {
      type: Array,
    },
  },
  data() {
    return {
      merchantCode: "",
      branchCode: "",
      cartName: "ORDER_INFO",
    };
  },
  async created() {
    this.cartName = await this.$store.dispatch("cartName");
    const merchantCode = this.$store.state.merchantCode;
    this.merchantCode = merchantCode ? `/${merchantCode}` : "";
    this.branchCode = this.$store.state.branchCode;
  },
  computed: {
    styleObject() {
      return {
          "--color": this.$store.state.color,
      };
    },
  },
  methods: {
    removeItemOutStock() {
      $(".btn-close-out-stock-items").click();
      var data = this.itemSelect;
      for (let i = 0; i < data.length; i++) {
        const item = data[i];
        if (item && item.isCanGet === 1) {
          if (item.stock_balance <= 0) {
            data.splice(i, 1);
            i--;
          } else {
            if (item.quantity > item.stock_balance && item.stock_balance) {
              item.isCanGet = 2;
              item.quantity = item.stock_balance;
              i--;
            }
          }
        }
        if (item && item.modifier_group) {
          for (let j = 0; j < item.modifier_group.length; j++) {
            const items = item.modifier_group[j].items;
            const modifierObject = item.modifier_group[j];
            for (let k = 0; k < items.length; k++) {
              const item2 = items[k];
              if (item2 && item2.isCanGet === 1) {
                if (
                  modifierObject.min_qty == 1 &&
                  modifierObject.max_qty == 1
                ) {
                  data.splice(i, 1);
                  i--;
                  break;
                } else if (
                  modifierObject.min_qty > 1 &&
                  modifierObject.max_qty > 1 &&
                  modifierObject.min_qty == modifierObject.max_qty
                ) {
                  data.splice(i, 1);
                  i--;
                  break;
                } else if (
                  modifierObject.min_qty >= 1 &&
                  modifierObject.min_qty < modifierObject.max_qty
                ) {
                  data.splice(i, 1);
                  i--;
                  break;
                } else {
                  if (item2.stock_balance <= 0) {
                    items.splice(k, 1);
                    k--;
                  } else {
                    if (
                      item2.quantity > item2.stock_balance &&
                      item2.stock_balance
                    ) {
                      item2.isCanGet = 2;
                      item2.quantity = item2.stock_balance;
                      k--;
                    }
                  }
                  if (items && items.length == 0) {
                    item.modifier_group.splice(j, 1);
                    j--;
                  }
                  break;
                }
              }
            }
          }
        }
      }
      const duplicateObjects = this.mergeItems(data);
      localStorage.removeItem(this.cartName);
      localStorage.setItem(this.cartName, JSON.stringify(duplicateObjects));
      EventBus.emit("ClearItemsOutStockSuccess");
    },
    mergeItems(items) {
      const mergedItems = [];

      // Helper function to check if two modifier groups are identical
      const areModifierGroupsIdentical = (group1, group2) => {
        if (group1.items.length !== group2.items.length) {
          return false;
        }

        for (let i = 0; i < group1.items.length; i++) {
          const item1 = group1.items[i];
          const item2 = group2.items[i];
          if (item1.id !== item2.id || item1.quantity !== item2.quantity) {
            return false;
          }
        }

        return true;
      };

      items.forEach((item) => {
        let merged = false;
        for (let i = 0; i < mergedItems.length; i++) {
          if (
            mergedItems[i].id === item.id &&
            areModifierGroupsIdentical(
              mergedItems[i].modifier_group[0],
              item.modifier_group[0]
            )
          ) {
            mergedItems[i].quantity += item.quantity;
            merged = true;
            break;
          }
        }
        if (!merged) {
          mergedItems.push({ ...item });
        }
      });

      return mergedItems;
    },
  },
};
</script>

<style scoped>
.modal-dialog {
  top: 50%;
  transform: translate(0, -50%) !important;
  color: black;
}

.btn-close-out-stock-items {
  font-size: 32px !important;
  /* font-weight: bold; */
  color: var(--color) !important;
  padding-right: 0 !important;
  cursor: pointer;
}

.btn-close-modal {
  background-color: transparent !important;
  border: 1px solid var(--color) !important;
  border-radius: 5px;
  color: var(--color) !important;
  padding: 5px 30px;
  /* font-weight: bold; */
}

.text-title {
  font-size: 18px;
  /* font-weight: bold; */
}

.text-sign-up {
  cursor: pointer;
  color: #1a73e8 !important;
  text-decoration: underline;
}
</style>
