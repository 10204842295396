<template>
    <div class="page-header align-items-start min-vh-100" :style="styleObject">
        <span class="mask bg-white"></span>
  
        <div class="container" style="padding-left: 0px;padding-right: 0px;">
            <div class="row">
                <div class="notifications">
                    <material-alert v-if="error" color="danger" icon="ni ni-like-2 ni-lg" dismissible>
                    {{ error }}
                    </material-alert>
                </div>
                <div class="col-lg-4 col-md-8 col-12">
                    <div class="card-body">
                        <div style="display: inline-flex;align-items: center;justify-content: space-between;width: 100%;">
                            <a href="check-out">
                                <span class="material-icons color-green md-30">arrow_back</span>
                            </a>
                            <div class="text-bold-500">
                                Select Voucher
                            </div>
                            <div></div>
                            <!-- <span class="material-icons color-green md-20">help</span> -->
                        </div>
                        <div style="width: 100%;display: inline-flex;align-items: center;gap: 5px;margin-top: 20px;">
                            <div style="width: 80%;">
                                <input v-model="searchKeyword" type="text" placeholder="Enter Voucher" style="width:100%;text-align:left;border: 1px solid #bdbdbd;border-radius: 5px;height: 40px;" />
                            </div>
                            <div style="width: 20%;">
                                <button
                                    style="
                                        width: 100%;
                                        padding-left: 6px;
                                        margin-bottom: 0px;
                                        border-radius: 5px;
                                        padding-right: 6px;
                                    "
                                    @click="getList()"
                                    class="btn bg-green text-center"
                                    type="button"
                                >
                                    SEARCH
                                </button>
                            </div>
                        </div>
                        <div style="width: 100%;margin-top: 20px;border: 0.5px solid #ececec;display: inline-flex;" v-for="(item, index) in promocodeData" :key="index">
                            <div style="width: 30%;">
                                <img :src="`${item.icon_url}`" style="width: 100%;height: 100px;"/>
                            </div>
                            <div style="width:55%;padding: 10px;">
                                <div class="text-bold-500" style="font-size: 14px;">
                                    {{ item.reward_title ?? "" }}
                                </div>
                                <div style="font-size: 11px;color:#DB8991;">
                                    Valid until: {{ item.expiry_date_text }}
                                </div>
                            </div>
                            <div style="width:15%;padding:10px;justify-content: center;display: flex;position: relative;">
                                <input type="radio" v-model="selectedItem" :value="item" />
                                <!-- <div style="font-size: 11px;color:#658ED1;position: absolute;bottom: 5px;right: 5px;">
                                    Condition
                                </div> -->
                            </div>
                        </div>
                    </div>
                    
                    <div class="card-body" v-if="selectVoucher">
                        <span style="color: red; font-size: 12px;">Please select Voucher</span>
                    </div>

                    <div class="card-body">
                        <div style="display: inline-flex;align-items: center; width: 100%;">
                            <button
                                style="width: 100%;"
                                @click="handleProceed()"
                                class="btn bg-green text-center"
                                type="button"
                            >
                                Agree
                            </button>
                        </div>
                    </div>

                    <div style="margin-bottom: 20px;"></div>
                </div>
            </div>
        </div>
        <div class="position-fixed top-1 end-1 z-index-2">
            <material-snackbar v-if="snackbar === 'danger'" title="" date="" description="Email or password is incorrect"
            :icon="{ component: 'campaign', color: 'white' }" color="danger" :close-handler="closeSnackbar" />
        </div>
        <material-loading :active="loaderActive" />
    </div>
  </template>
  
<script>
    import MaterialAlert from "@/components/MaterialAlert.vue";
    import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
    import MaterialLoading from "@/components/MaterialLoading.vue";
    import PromocodeService from "../services/promocode.service";
    import { localStorageName } from '@/config/utils';
  
    export default {
        name: "sign-in",
        components: {
            MaterialAlert,
            MaterialSnackbar,
            MaterialLoading
        },
        data() {
            return {
                snackbar: null,
                error: '',
                paymentMethod: '',
                branch: JSON.parse(localStorage.getItem(localStorageName('BRANCH_INFO'), '')),
                cutleryValue: 1,
                cutleryChecked: true,
                memberInfo: JSON.parse(localStorage.getItem(localStorageName('memberInfo'), '')),
                memberPhone: null,
                memberName: null,
                showErrorName: false,
                showErrorPhone: false,
                loaderActive: false,
                promocodeData: [],
                searchKeyword: '',
                selectedItem: null,
                selectVoucher: false
            };
        },
        created() {
            var memberInfoData = JSON.parse(localStorage.getItem(localStorageName('memberInfo'), ''));
            this.memberName = memberInfoData.name;
            this.memberPhone = "+"+memberInfoData.phone_code+memberInfoData.phone_number;
            this.getList();
        },
        computed: {
            styleObject() {
                return {
                    "--color": this.$store.state.color,
                };
            },
        },
        methods: {
            closeSnackbar() {
                this.snackbar = null;
            },
            selectPaymentMethod(paymentMethod) {
                this.paymentMethod = paymentMethod;
            },
            gotoBackOrderPage() {
                this.$router.push('order')
            },
            handleProceed() {
                if(this.selectedItem && this.selectedItem.id) {
                    this.selectVoucher = false;
                    this.$router.push({ name: 'CheckOut', params: { selectedPromocodeId: this.selectedItem.id, selectedPromocodeName: this.selectedItem.reward_title } });
                } else {
                    this.selectVoucher = true;
                }
                // if(!this.memberName) {
                //     this.showErrorName = true;
                // } else {
                //     this.showErrorName = false;
                // }
                // if(!this.memberPhone) {
                //     this.showErrorPhone = true;
                // } else {
                //     this.showErrorPhone = false;
                // }
                // if(this.memberName && this.memberPhone) {
                //     console.log("handle stripe payment here");
                // }
            },
            gotoPromocodePage() {
                this.$router.push('select-promo-code')
            },
            setTimeOutNotification() {
                setTimeout(() => {
                    this.snackbar = null;
                }, 3000);
            },

            showLoader() {
                this.loaderActive = true;
            },

            hideLoader() {
                this.loaderActive = false;
            },
            getList() {
                this.showLoader();

                // var token = localStorage.getItem("token");

                let dataForm = {
                    memberId: this.memberInfo.id,
                    search_keyword: this.searchKeyword
                };

                PromocodeService.getListPromocode(dataForm).then(
                    (response) => {
                        if (response.data.result.isSuccess) {
                            this.promocodeData = response.data.data;
                        } else {
                            this.snackbar = "danger";
                            this.message = response.data.result.message;
                            this.setTimeOutNotification();
                        }
                        this.isLoadDone = true;
                        this.hideLoader();
                    },
                    (error) => {
                        this.snackbar = "danger";
                        this.message = error;
                        this.hideLoader();
                    }
                );
            },
        },
    };
</script>
<style>
    .page-header {
        padding-bottom: 0 !important;
    }
    input::placeholder {
        font-size: 15px;
        text-align: left;
        font-weight: 600;
        padding-left: 10px;
    }
    .visa {
        width:50%;text-align:left;border: 1px solid #bdbdbd;border-radius: 5px;height: 80px;
    }
    .visaActive {
        width:50%;text-align:left;border: 5px solid var(--color);border-radius: 5px;height: 80px;
    }
    .master {
        width:50%;text-align:left;border: 1px solid #bdbdbd;border-radius: 5px;height: 80px;
    }
    .masterActive {
        width:50%;text-align:left;border: 5px solid var(--color);border-radius: 5px;height: 80px;
    }
    .googlePay {
        width:50%;text-align:left;border: 1px solid #bdbdbd;border-radius: 5px;height: 80px;
    }
    .googlePayActive {
        width:50%;text-align:left;border: 5px solid var(--color);border-radius: 5px;height: 80px;
    }
    .applePay {
        width:50%;text-align:left;border: 1px solid #bdbdbd;border-radius: 5px;height: 80px;
    }
    .applePayActive {
        width:50%;text-align:left;border: 5px solid var(--color);border-radius: 5px;height: 80px;
    }
    .text-bold-500 {
        color: black;font-weight: 500;
    }
    .line {
        width: 100%; height: 5px;background:#bdbdbd;
    }
    .text-end {
        color:black;width:20%;text-align: end;
    }
</style>
  