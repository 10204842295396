<template>
    <div class="container-custom" :style="styleObject">
        <header>
            <div style="display: inline-flex; align-items: center;justify-content: space-between;width: 100%;background: var(--color);">
                <a @click="gotoBackProfile()" style="width: 10%;height: 100%;display: flex; align-items: center; justify-content: center;">
                    <span class="material-icons color-green md-30" style="color: black !important;">arrow_back</span>
                </a>
                <div class="text-bold-500" style="width: 75%;align-items: center;justify-content: center;display: flex;font-weight:bold !important;">
                    Order History
                </div>
                <!-- <span class="material-icons color-green md-20">help</span> -->
                <a @click="openPopupReward" style="width: 15%;height: 48px;">
                    <!-- <img src="../assets/img/collect-reward.png" class="avatar avatar-md border-radius-lg" alt="user1" /> -->
                </a>
            </div>
            <!-- <div class="row d-flex justify-content-center" style="background: white;">
                <ul class="reward-tab">
                    <li
                    class="nav-item active"
                    id="nav_item_1"
                    @click="changeTab(1)"
                    :style="`color: ${this.$store.state.labelColor} !important`"
                    >
                    <strong>Ongoing</strong>
                    </li>
                    <li
                    class="nav-item"
                    id="nav_item_2"
                    @click="changeTab(2)"
                    :style="`color: ${this.$store.state.labelColor} !important`"
                    >
                    <strong>Completed</strong>
                    </li>
                </ul>
            </div>  -->
        </header>
        <div class="content-custom mt-3">
            <div style="height: 100%;">
                <div style="height: 100%;overflow: auto;">
                    <div class="wrap-card ps-1 pe-1" v-if="dataOrders.length">
                        <div v-if="typeChecked == 1">
                            <div v-for="(itemMain, index) in dataOrders" :key="index">
                                <div style="padding-left: 1em;padding-right: 1em;margin-top: 8px;">
                                    <div style="display: flex;justify-content: space-between;align-items: center;">
                                        <div style="color: black;">Order <span style="color: gray;">#{{ merchantCode + '-' +  itemMain.order_number}}</span></div>
                                        <div style="color: gray;font-size: 14px;">{{ dateFormat(itemMain.created_at) }}</div>
                                    </div>
                                    <div style="margin-top: 15px;width: 100%;height: auto;">
                                        <div class="div-order-items" v-for="(item, index) in itemMain.items_ordering_data" :key="index" style="display: flex;align-items: center;">
                                            <div style="width: 100%;display: flex;align-items: center;">
                                                <div style="width: 10%;height: 40px;">
                                                    <div style="border: 1px solid #D8DADA;text-align:center;display: flex;align-items: center;justify-content: center;height: 100%;">
                                                        {{ item.quantity }}
                                                    </div>
                                                </div>
                                                <div style="width: 3%"></div>
                                                <div style="width: 87%;display: flex;border-bottom: 0.5px solid #D8DADA;padding-top: 10px;padding-bottom: 10px;">
                                                    <div style="width: 20%;align-items: center;display: flex;">
                                                        <img :src="item.image" style="width: 50px;height: 50px;"/>
                                                    </div>
                                                    <div style="width: 80%;align-items: center;justify-content: center;display: flex;">
                                                        <div class="div-items" style="position: relative;">
                                                            <div class="item-name" style="width: 70%;line-height: normal;">
                                                                <span style="display: -webkit-box;-webkit-box-orient: vertical;overflow: hidden;-webkit-line-clamp: 2;">{{ item.item_name}}</span>
                                                                <div v-for="(modifierOption, indexModifierOption) in item.modifier_group" :key="indexModifierOption">
                                                                    <div style="position: relative;display: grid;" class="div-items" v-for="(itemModifierOption, indexItemModifierOption) in modifierOption.items" :key="indexItemModifierOption">
                                                                        <div class="div-items" v-for="(itemQuantity, indexQuantity) in itemModifierOption.quantity" :key="indexQuantity">
                                                                            <div class="item-name" style="width:100%">
                                                                                <span :style="{ opacity: itemModifierOption.isCanGet == 1 ? 0.3 : 1 }" style="font-size: 12px;">{{ itemModifierOption.item_name }}</span>
                                                                                <div v-if="itemModifierOption.isCanGet == 1" style="color: var(--color);font-size: 12px;">NO MORE AVAILABLE</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="item-price-format" style="width: 30%;opacity: 1;align-items: center;display: flex;justify-content: end;">
                                                                <div style="display: grid;">
                                                                    <span style="color: #bdbdbd;text-decoration:line-through;font-size: 12px;">{{ getMainItem(item, itemMain.isNonMember) }}</span>
                                                                    {{ getMainItemPriceAndModifiersPrice(item, itemMain.isNonMember) }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div style="width: 85%;display: flex;">
                                            <div style="width: 47%;height: 100px;margin-right: 3%;" v-for="(itemOrder, index) in item.OrderItems.slice(0, 2)" :key="index">
                                                <img :src="itemOrder.ItemImage" style="width: 100%;height: 100%;" />
                                                <div style="color:black;text-align: center;font-size:12px;margin-top: 8px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;padding-left: 10px; padding-right: 10px;">{{ itemOrder.ItemName }}</div>
                                            </div>
                                        </div>
                                        <div v-if="item.OrderItems && item.OrderItems.length > 2" style="width:15%;height:100%;align-items: center;justify-content: center;display: flex;color: gray;font-size: 30px;">
                                            <div style="margin-top: -50px;margin-left: 20px;">+{{ item.OrderItems.length - 2 }}</div>
                                        </div> -->
                                    </div>
                                    <div style="width: 100%;margin-top: 10px;">
                                        <div style="color:black;text-align: right;">
                                            {{ countQuantityItems(itemMain) }} items
                                        </div>
                                    </div>
                                    <div v-if="tableCode" style="display: flex;justify-content: space-between;align-items: center;">
                                        <div style="display:flex;align-items: center;gap: 5px;">
                                            <span class="material-icons color-green" style="">shopping_basket</span>
                                            <!-- <div style="color: black;font-weight:bold;" v-if="itemMain.status && itemMain.status == 2">
                                                Ordering
                                            </div> -->
                                            <!-- <div style="color: black;font-weight:bold;" v-else-if="itemMain.is_pushed_to_megapos_database && itemMain.is_pushed_to_megapos_database == 2">
                                                Ready for collection
                                            </div> -->
                                            <!-- <div style="color: black;font-weight:bold;" v-else-if="itemMain.status && itemMain.status == 3">
                                                Seated
                                            </div> -->
                                            <div style="color: black;font-weight:bold;">
                                                Seated
                                            </div>
                                        </div>
                                        <div style="color: black;font-weight:bold;">{{ Number(itemMain.topup_direct_deduct) > 0 ? formatPrice(itemMain.amount_direct_deduct) :  formatPrice(itemMain.payment_amount) }}</div>
                                    </div>
                                    <div v-else style="display: flex;justify-content: space-between;align-items: center;">
                                        <div style="display:flex;align-items: center;gap: 5px;">
                                            <span class="material-icons color-green" style="">shopping_basket</span>
                                            <div style="color: black;font-weight:bold;" v-if="itemMain.is_pushed_to_megapos_database && itemMain.is_pushed_to_megapos_database == 1">
                                                Preparing
                                            </div>
                                            <div style="color: black;font-weight:bold;" v-else>
                                                Confirming
                                            </div>
                                        </div>
                                        <div style="color: black;font-weight:bold;">{{ Number(itemMain.topup_direct_deduct) > 0 ? formatPrice(itemMain.amount_direct_deduct) :  formatPrice(itemMain.payment_amount) }}</div>
                                    </div>
                                    <!-- <div style="width:100%;border-bottom: 0.5px solid lightgray;margin-top: 10px"></div> -->
                                    <div v-if="!tableCode"  style="width:100%;margin-top:10px;justify-content: start;display: flex;">
                                        <div style="display: flex;gap: 10px;">
                                            <div @click="viewDetailOrder(itemMain.transaction_id)" style="width: 130px;text-align:center;font-weight:normal;border: 1px solid lightgray;border-radius: 10px;color: black;padding-top: 5px;padding-bottom: 5px;padding-left: 10px;padding-right: 10px;">View</div>
                                            <!-- <div style="width: 130px;text-align:center;font-weight:bold;background-color:var(--color);border-radius: 10px;color: white;padding-top: 5px;padding-bottom: 5px;padding-left: 10px;padding-right: 10px;">Re-order</div> -->
                                        </div>
                                    </div>
                                </div>
                                <div style="width: 100%;border-bottom: 10px solid lightgray;margin-top: 10px;"></div>
                            </div>
                            <div v-if="totalRealOrder > 5" style="width: 100%;align-items: center;display: flex;justify-content: center;">
                                <div @click="loadMore(1)" style="width: 50%;text-align:center;font-weight:bold;background-color:var(--color);border-radius: 30px;color: white;padding-top: 5px;padding-bottom: 5px;padding-left: 10px;padding-right: 10px;margin-top: 20px;margin-bottom: 20px;">
                                    Load more
                                </div>
                            </div>
                        </div>
                        <div v-if="typeChecked == 2">
                            <div v-for="(itemMain, index) in dataOrders" :key="index">
                                <div style="padding-left: 1em;padding-right: 1em;margin-top: 8px;">
                                    <div style="display: flex;justify-content: space-between;align-items: center;">
                                        <div style="color: black;">Order <span style="color: gray;">#{{ itemMain.receipt_no }}</span></div>
                                        <div style="color: gray;font-size: 14px;">{{ dateFormat(itemMain.created_at) }}</div>
                                    </div>
                                    <div style="margin-top: 15px;width: 100%;height: auto;">
                                        <div class="div-order-items" v-for="(item, index) in itemMain.items_ordering_data" :key="index" style="display: flex;align-items: center;">
                                            <div style="width: 100%;display: flex;align-items: center;">
                                                <div style="width: 10%;height: 40px;">
                                                    <div style="border: 1px solid #D8DADA;text-align:center;display: flex;align-items: center;justify-content: center;height: 100%;">
                                                        {{ item.quantity }}
                                                    </div>
                                                </div>
                                                <div style="width: 3%"></div>
                                                <div style="width: 87%;display: flex;border-bottom: 0.5px solid #D8DADA;padding-top: 10px;padding-bottom: 10px;">
                                                    <div style="width: 20%;align-items: center;display: flex;">
                                                        <img :src="item.image" style="width: 50px;height: 50px;"/>
                                                    </div>
                                                    <div style="width: 80%;align-items: center;justify-content: center;display: flex;">
                                                        <div class="div-items" style="position: relative;">
                                                            <div class="item-name" style="width: 70%;line-height: normal;">
                                                                <span style="display: -webkit-box;-webkit-box-orient: vertical;overflow: hidden;-webkit-line-clamp: 2;">{{ item.item_name}}</span>
                                                                <div v-for="(modifierOption, indexModifierOption) in item.modifier_group" :key="indexModifierOption">
                                                                    <div style="position: relative;display: grid;" class="div-items" v-for="(itemModifierOption, indexItemModifierOption) in modifierOption.items" :key="indexItemModifierOption">
                                                                        <div class="div-items" v-for="(itemQuantity, indexQuantity) in itemModifierOption.quantity" :key="indexQuantity">
                                                                            <div class="item-name" style="width:100%">
                                                                                <span :style="{ opacity: itemModifierOption.isCanGet == 1 ? 0.3 : 1 }" style="font-size: 12px;">{{ itemModifierOption.item_name }}</span>
                                                                                <div v-if="itemModifierOption.isCanGet == 1" style="color: var(--color);font-size: 12px;">NO MORE AVAILABLE</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="item-price-format" style="width: 30%;opacity: 1;align-items: center;display: flex;justify-content: end;">
                                                                <div style="display: grid;">
                                                                    <span style="color: #bdbdbd;text-decoration:line-through;font-size: 12px;">{{ getMainItem(item, itemMain.isNonMember) }}</span>
                                                                    {{ getMainItemPriceAndModifiersPrice(item, itemMain.isNonMember) }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div style="width: 85%;display: flex;">
                                            <div style="width: 47%;height: 100px;margin-right: 3%;" v-for="(itemOrder, index) in itemMain.OrderItems.slice(0, 2)" :key="index">
                                                <img :src="itemOrder.ItemImage" style="width: 100%;height: 100%;" />
                                                <div style="color:black;text-align: center;font-size:12px;margin-top: 8px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;padding-left: 10px; padding-right: 10px;">{{ itemOrder.ItemName }}</div>
                                            </div>
                                        </div>
                                        <div v-if="itemMain.OrderItems && itemMain.OrderItems.length > 2" style="width:15%;height:100%;align-items: center;justify-content: center;display: flex;color: gray;font-size: 30px;">
                                            <div style="margin-top: -50px;margin-left: 20px;">+{{ itemMain.OrderItems.length - 2 }}</div>
                                        </div> -->
                                    </div>
                                    <div style="width: 100%;margin-top: 10px;">
                                        <div style="color:black;text-align: right;">
                                            {{ countQuantityItems(itemMain) }} items
                                        </div>
                                    </div>
                                    <div style="display: flex;justify-content: space-between;align-items: center;">
                                        <div style="display:flex;align-items: center;gap: 5px;">
                                            <span class="material-icons color-green" style="color: green !important;">check_circle</span>
                                            <div style="color: black;font-weight:bold;">
                                                Completed
                                            </div>
                                        </div>
                                        <div style="color: black;font-weight:bold;">{{ Number(itemMain.topup_direct_deduct) > 0 ? formatPrice(itemMain.amount_direct_deduct) :  formatPrice(itemMain.payment_amount) }}</div>
                                    </div>
                                    <!-- <div style="width:100%;border-bottom: 0.5px solid lightgray;margin-top: 10px"></div> -->
                                    <div v-if="!tableCode" style="width:100%;margin-top:10px;justify-content: start;display: flex;">
                                        <div style="display: flex;gap: 10px;">
                                            <div @click="viewDetailOrder(itemMain.transaction_id)" style="width: 130px;text-align:center;font-weight:normal;border: 1px solid lightgray;border-radius: 10px;color: black;padding-top: 5px;padding-bottom: 5px;padding-left: 10px;padding-right: 10px;">View</div>
                                            <!-- <div style="width: 130px;text-align:center;font-weight:bold;background-color:var(--color);border-radius: 10px;color: white;padding-top: 5px;padding-bottom: 5px;padding-left: 10px;padding-right: 10px;">Re-order</div> -->
                                        </div>
                                    </div>
                                </div>
                                <div style="width: 100%;border-bottom: 10px solid lightgray;margin-top: 10px;"></div>
                            </div>
                            <div v-if="totalRealOrder > 5" style="width: 100%;align-items: center;display: flex;justify-content: center;">
                                <div @click="loadMore(2)" style="width: 50%;text-align:center;font-weight:bold;background-color:var(--color);border-radius: 30px;color: white;padding-top: 5px;padding-bottom: 5px;padding-left: 10px;padding-right: 10px;margin-top: 20px;margin-bottom: 20px;">
                                    Load more
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div v-else>
                        <div class="d-flex justify-content-center p-5" style="background-color: #f0f2f5;"> {{ typeChecked == 1 ? "You don't have any ongoing order" : "No order history available" }} </div>
                    </div>
                </div>
            </div>
        </div>
        <footer>
            <Footer></Footer>
            <VoucherAvailable></VoucherAvailable>
        </footer>
        <div class="position-fixed top-1 end-1 z-index-2">
            <material-snackbar
                v-if="snackbar === 'danger' || snackbar === 'success'"
                title=""
                date=""
                :description="message"
                :icon="{ component: 'campaign', color: 'white' }"
                :color="snackbar"
                :close-handler="closeSnackbar"
            />
        </div>
        <material-loading :active="loaderActive" />
    </div>
    
  </template>
  
  <script>
  import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
  import "bootstrap/dist/js/bootstrap.bundle";
  import MaterialLoading from "@/components/MaterialLoading.vue";
  import "bootstrap/js/dist/modal";
  import $ from "jquery";
  import Footer from "@/common/Footer/OrderFooter";
  import OrderService from "../services/order.service";
  import moment from 'moment';
  import { localStorageName } from '@/config/utils';
  
  export default {
    name: "my-profile",
    components: {
      MaterialSnackbar,
      MaterialLoading,
      Footer
    },
    data() {
      return {
        snackbar: null,
        loaderActive: false,
        typeChecked: 2,
        memberId: 0,
        valueFromParent: 'default_value',
        dataOrders: [],
        totalRealOrder: 0,
        page: 0,
        memberInfo: JSON.parse(localStorage.getItem(localStorageName('memberInfo'), '')),
        merchantCode: "",
        branchCode: "",
        pathType: "",
        tableCode: "",
      };
    },
    created() {
        this.merchantCode = this.$store.state.merchantCode;
        this.branchCode = this.$store.state.branchCode;
        this.pathType = this.$route.params.type;
        this.tableCode = this.$route.params.table_code ?? "";
        // if(this.tableCode != "") {
        //     this.$router.go(-1);
        // }
        this.changeTab(2);
    },
    computed: {
        styleObject() {
            return {
                "--color": this.$store.state.color,
            };
        },
    },
    methods: {
        closeSnackbar() {
            this.snackbar = null;
        },
        setTimeOutNotification() {
            setTimeout(() => {
            this.snackbar = null;
            }, 3000);
        },
        changeTab(type) {
            this.dataOrders = [];
            if(type == 2) {
                this.valueFromParent = Math.random();
                setTimeout(() => {
                    $(".nav-item").removeClass("active");
                    $("#nav_item_" + type).addClass("active");
                    this.typeChecked = type;
                }, 500);
            } else {
                $(".nav-item").removeClass("active");
                $("#nav_item_" + type).addClass("active");
                this.typeChecked = type;
            }

            if (this.tableCode) {
                this.getOrdersByTable(type);
            } else {
                if (this.memberInfo) {
                    this.getOrdersByMember(type);
                } else {
                    this.getOrdersByNonMember(type);
                }
            }
        },
        showLoader() {
            this.loaderActive = true;
        },
        hideLoader() {
            this.loaderActive = false;
        },
        goBack() {
            this.$router.go(-1);
        },
        loadMore(type) {
            if (this.memberInfo) {
                this.getOrdersByMember(type);
            } else {
                this.getOrdersByNonMember(type);
            }
        },
        getOrdersByMember(type) {
            this.showLoader();
            const currentPage = Math.ceil(this.dataOrders.length / (this.page === 0 ? 5 : 3));
            const perPage = this.page === 0 ? 5 : 3;

            OrderService.getOrdersByMember(this.branchCode, type == 1 ? "PAID" : "ACCEPTED", currentPage, perPage).then(
                (response) => {
                    if (response.data.result.isSuccess) {
                        this.page = response.data.data.page;
                        this.dataOrders = this.page === 0 ? response.data.data.data : this.dataOrders.concat(response.data.data.data);
                        if(response.data.data.data && response.data.data.data.length < 3) {
                            this.totalRealOrder = 0;
                        } else {
                            this.totalRealOrder = response.data.data.total;
                        }
                    } else {
                        this.snackbar = "danger";
                        this.message = response.data.result.message;
                        this.setTimeOutNotification();
                    }
                    this.hideLoader();
                },
                (error) => {
                    this.snackbar = "danger";
                    this.message = error;
                    this.hideLoader();
                }
            );
        },
        getOrdersByNonMember(type) {
            this.showLoader();
            const ordersId = JSON.parse(localStorage.getItem(localStorageName('ORDER_ID_NON_MEMBER'), []));
            const currentPage = Math.ceil(this.dataOrders.length / (this.page === 0 ? 5 : 3));

            var dataForm = {
                branch_code: this.branchCode,
                status: type == 1 ? "PAID" : "ACCEPTED",
                orders_id: ordersId,
                current_page: currentPage
            }

            OrderService.getOrdersByNonMember(dataForm).then(
                (response) => {
                if (response.data.result.isSuccess) {
                    this.page = response.data.data.page;
                    this.dataOrders = this.page === 0 ? response.data.data.data : this.dataOrders.concat(response.data.data.data);
                    if(response.data.data.data && response.data.data.data.length < 3) {
                        this.totalRealOrder = 0;
                    } else {
                        this.totalRealOrder = response.data.data.total;
                    }
                } else {
                    this.snackbar = "danger";
                    this.message = response.data.result.message;
                    this.setTimeOutNotification();
                }
                this.hideLoader();
                },
                (error) => {
                this.snackbar = "danger";
                this.message = error;
                this.hideLoader();
                }
            );
        },
        getOrdersByTable(type) {
            this.showLoader();
            var dataForm = {
                branch_code: this.branchCode,
                table_code: this.tableCode,
                status: type == 1 ? "PAID" : "ACCEPTED",
                member_id: this.memberInfo? this.memberInfo.id : 0
            }

            OrderService.getOrdersByTable(dataForm).then(
                (response) => {
                    if (response.data.result.isSuccess) {
                        this.dataOrders = response.data.data;
                        this.totalRealOrder = 0;
                    } else {
                        this.snackbar = "danger";
                        this.message = response.data.result.message;
                        this.setTimeOutNotification();
                    }
                    this.hideLoader();
                },
                (error) => {
                    this.snackbar = "danger";
                    this.message = error;
                    this.hideLoader();
                }
            );
        },
        dateFormat(date) {
            if (date == null) {
                return null;
            } else {
                return moment(date).format("YYYY-MM-DD HH:mm");
            }
        },
        viewDetailOrder(order_id) {
            this.$router.push({ name: "PaymentSuccess", params: {
                merchant_code: this.merchantCode,
                type: this.pathType,
                branch_code: this.branchCode,
                table_code: this.tableCode,
                order_id: order_id
            } });
        },
        getMainItemPriceAndModifiersPrice(item, isNonMember) {
            var priceMainAndModifer = 0;
            if (this.tableCode) {
                priceMainAndModifer = item.is_member && item.member_price_no_modifier_option ? item.member_price_no_modifier_option * item.quantity : item.price_default_no_modifier_option * item.quantity;
            } else {
                priceMainAndModifer = isNonMember == false && item.member_price_no_modifier_option ? item.member_price_no_modifier_option * item.quantity : item.price_default_no_modifier_option * item.quantity;
            }

            var totalPriceModifer = 0;
            if (item.modifier_group) {
                item.modifier_group.forEach((group) => {
                    group.items.forEach((itemModifier) => {
                        if (isNonMember) {
                            totalPriceModifer += itemModifier.price_default * itemModifier.quantity;
                        } else {
                            totalPriceModifer += itemModifier.member_price * itemModifier.quantity;
                        }
                    });
                });
            }

            return this.formatPrice(priceMainAndModifer + (totalPriceModifer * item.quantity));
        },
        getMainItem(item, isNonMember) {
            var priceMainAndModifer = 0;
            if (this.tableCode) {
                isNonMember = !item.is_member;
            }
            priceMainAndModifer = isNonMember == false && item.member_price_no_modifier_option ? item.price_default_no_modifier_option * item.quantity : 0;

            var totalPriceModifer = 0;
            if (item.modifier_group) {
                item.modifier_group.forEach((group) => {
                    group.items.forEach((itemModifier) => {
                        if (isNonMember) {
                            totalPriceModifer += itemModifier.price_default * itemModifier.quantity;
                        } else {
                            totalPriceModifer += itemModifier.member_price * itemModifier.quantity;
                        }
                    });
                });
            }
            
            return priceMainAndModifer > 0 ? this.formatPrice(priceMainAndModifer + (totalPriceModifer * item.quantity)) : "";
        },
        formatPrice(price) {
            return 'S$ ' + parseFloat(price).toFixed(2);
        },
        countQuantityItems(item) {
            var totalQuantityItems = 0;

            item.items_ordering_data.forEach((mainItem) => {
                totalQuantityItems += mainItem.quantity;
                // mainItem.modifier_group.forEach((group) => {
                //     group.items.forEach((itemModifier) => {
                //         totalQuantityItems += itemModifier.quantity;
                //     });
                // });
            });

            return totalQuantityItems;
        },
        gotoBackProfile() {
            this.$router.push('profile')
        },
    },
  };
  </script>
  <style scoped>
  .btn-exchange {
    color: rgb(248, 37, 37);
    font-weight: 700;
    border: 1px solid rgb(248, 37, 37);
  }
  
  .reward-tab {
    margin: 0px !important;
    padding: 0px;
    width: 100% !important;
  }
  
  .reward-tab li {
    list-style: none;
    float: left;
    width: 50%;
    text-align: center;
    padding: 5px;
    cursor: pointer;
  }
  
  .reward-tab li.active {
    border-bottom: 2px solid var(--color);
    color: var(--color);
  }

  @media only screen and (max-width: 600px) {
    .wrap-card .card-item:last-child {
      margin-bottom: 80px !important;
    }
  }
  .container-custom {
      display: flex;
      flex-direction: column;
      height: 100%;
      background-color: white;
  }

  .content-custom {
      flex: 1;
      background-color: white;
      overflow: auto;
      /* padding: 0px; */
  }
  footer {
    background-color: white;
    padding-top: 10px;
  }
  .div-order-items {
    width: 100%;display: inline-flex;
    margin-top: 8px;
}
  </style>