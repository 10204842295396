<template>
  <div :style="styleObject">
    <button
      style="display: none"
      type="button"
      class="btn-notification-sign-in-modal"
      data-bs-toggle="modal"
      data-bs-target="#modalNotificationSignIn"
    ></button>
    <div
      class="modal fade"
      id="modalNotificationSignIn"
      tabindex="-1"
      aria-labelledby="modalNotificationLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div
            class="modal-header d-flex justify-content-end"
            style="border: 0; padding: 0.1rem 1rem"
          >
            <div class="btn-close" data-bs-dismiss="modal">&times;</div>
          </div>
          <div class="modal-body text-center">
            <div class="text-title">{{ message }}</div>
            <button
              @click="gotoSignIn()"
              type="button"
              class="btn-close-modal mt-3"
            >
              Login
            </button>
            <div class="mt-3">Don't have account yet?<span class="text-sign-up ms-1" @click="gotoSignUp()">Sign up</span></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import { gotoMemberLogin } from '../config/utils';

export default {
  name: "ExchangeStatus",
  props: {
    message: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      merchantCode: "",
      branchCode: "",
    }
  },
  created() {
    const merchantCode = this.$store.state.merchantCode;
    this.merchantCode = merchantCode ? `/${merchantCode}` : "";
    this.branchCode = this.$store.state.branchCode;
  },
  computed: {
    styleObject() {
      return {
          "--color": this.$store.state.color,
      };
    },
  },
  methods: {
    gotoSignIn() {
      $('.btn-close').click();
      // this.$router.push({ name: 'SignIn', params: {  } });
      gotoMemberLogin('mycart');
      // window.open(process.env.VUE_APP_MEMBER_SITE_URL + this.merchantCode + "/sign-in?branch_code=" + this.branchCode + "&from=mycart","_self");
    },

    gotoSignUp() {
      $('.btn-close').click();
      gotoMemberLogin('mycart');
      // window.open(process.env.VUE_APP_MEMBER_SITE_URL + this.merchantCode + "/sign-in?branch_code=" + this.branchCode + "&from=mycart", "_self");
    },
  }
};
</script>

<style scoped>
.modal-dialog {
  top: 50%;
  transform: translate(0, -50%) !important;
  color: black;
}

.btn-close {
  font-size: 32px !important;
  /* font-weight: bold; */
  color: var(--color) !important;
  padding-right: 0 !important;
  cursor: pointer;
}

.btn-close-modal {
  border: 1px solid orangered;
  background-color: orangered;
  border-radius: 5px;
  color: #ffffff;
  padding: 5px 30px;
  /* font-weight: bold; */
}

.text-title {
  font-size: 18px;
  /* font-weight: bold; */
}

.text-sign-up {
  cursor: pointer;
  color:#1a73e8 !important;
  text-decoration: underline;
}
</style>
