<template>
  <div class="page-header align-items-start">
    <span class="mask bg-white"></span>
    <div class="container">
      <div id="notfound">
        <div class="notfound">
          <div class="notfound-404">
            <div></div>
            <h1>419</h1>
          </div>
          <h2>Table has expired!</h2>
          <p>Please contact staff for a new QR code.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageExpired",
};
</script>

<style scoped>
.page-header {
  position: relative;
}
#notfound {
  position: relative;
  height: 100vh;
}
#notfound .notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.notfound {
  max-width: 460px;
  width: 100%;
  text-align: center;
  line-height: 1.4;
}
.notfound .notfound-404 {
  position: relative;
  width: 180px;
  height: 180px;
  margin: 0 auto 50px;
}
.notfound .notfound-404 > div:first-child {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #ffa200;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  border: 5px dashed #000;
  border-radius: 5px;
}
.notfound .notfound-404 h1 {
  color: #000;
  font-weight: 700;
  margin: 0;
  font-size: 90px;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  left: 50%;
  text-align: center;
  height: 40px;
  line-height: 40px;
}
.notfound p {
  font-size: 16px;
  color: #000;
  font-weight: 400;
}
</style>
