import api from './api';
import store from '@/store';

class MemberServie {
  getDataMember(dataForm) {
    return api.post('/get-data-member', dataForm);
  }

  getTopupList() {
    return api.get(`${process.env.VUE_APP_BASE_MINTY_URL}/member/topup-list`);
  }

  addDirectAmountAfterPayWithCard(session_id, amount_direct_increased, id, merchant_code) {
    return api.post(`${process.env.VUE_APP_BASE_MINTY_URL}/member/add-direct-amount-after-pay-with-card`, { session_id: session_id, amount_direct_increased: amount_direct_increased, topup_id: id }, {
        headers: {
        'MerchantCode': merchant_code
        }
      }
    );
  }

  checkMemberPermissions() {
    return api.post(`${process.env.VUE_APP_BASE_MINTY_URL}/member/check-member-permissions`, {
      merchant_code: store.state.merchantCode
    });
  }
}

export default new MemberServie();
