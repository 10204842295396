import router from "@/router";
import store from "@/store";
/**
 * Go to member login
 */
export const gotoMemberLogin = (from = "") => {
  var merchantCode = store.state.merchantCode;
  merchantCode = merchantCode ? `/${merchantCode}` : "";
  var branchCode = store.state.branchCode;
  var tableCode = router.currentRoute.value.params.table_code;

  var url =
    process.env.VUE_APP_MEMBER_SITE_URL +
    merchantCode +
    "/sign-in?branch_code=" +
    branchCode;

  if (tableCode) {
    url += "&table_code=" + tableCode;
  }

  if (from) {
    url += "&from=" + from;
  }

  window.open(url, "_self");
};

export const localStorageName = (name) => {
    return name + '_' + store.state.merchantCode + '_' + store.state.branchCode;
}
