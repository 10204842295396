<template>
  <div v-if="active" class="loader-wrapper">
    <div class="loader">
      <div></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Loader',
  props: {
    active: Boolean,
    text: String
  }
}
</script>

<style scoped>
.loader-wrapper {
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999 !important;
}

.loader {
  display: inline-block;
  width: 80px;
  height: 80px;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -40%);
  z-index: 9999;
}

.loader div {
  width: 60px;
  height: 60px;
  border: 11px dotted black;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 1.5s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 
</style>