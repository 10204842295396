<template>
  <div class="page-header align-items-start" :style="styleObject">
    <span class="mask bg-white"></span>

    <div class="container" style="padding-left: 0px; padding-right: 0px">
      <div class="row">
        <div class="notifications">
          <material-alert
            v-if="error"
            color="danger"
            icon="ni ni-like-2 ni-lg"
            dismissible
          >
            {{ error }}
          </material-alert>
        </div>
        <div class="col-lg-4 col-md-8 col-12">
          <div class="card-body" style="background: var(--color); padding: 1px 5px">
            <div class="flex-container">
              <a @click="goBack" class="back-button">
                <span
                  class="material-icons md-30"
                  style="line-height: inherit; color: black; margin-top: -2px"
                  >close</span
                >
              </a>
              <div class="cart-title">Order Status</div>
            </div>
          </div>
          <div class="card-body mt-3">
            <div style="display: inline-flex; align-items: center">
              <div
                style="font-size: 18px; font-weight: bold; color: #8e272c"
                v-if="orderDetail"
              >
                TABLE IS LOCKED BY CASHIER, PLEASE CONTACT STAFF FOR HELP IF
                THIS STATUS PERSIST.
              </div>
            </div>
            <div class="d-flex align-items-center">
              Order #{{ merchantCode }}
              -
              <span
                v-if="orderDetail"
                style="
                  padding-left: 5px;
                  font-size: 24px;
                  color: #2db550;
                  font-weight: bold;
                "
                >{{ orderDetail.order_number }}</span
              >
            </div>
          </div>
          <div class="card-body mt-3 pb-0">
            <div style="display: inline-flex; align-items: center; width: 100%">
              <div style="width: 100%" class="text-center">
                <button
                  @click="handleRetry()"
                  class="btn bg-green text-center mb-0"
                  type="button"
                  style="
                    color: white !important;
                    background: var(--color) !important;
                    border-radius: 10px !important;
                  "
                >
                  RETRY
                </button>
              </div>
            </div>
          </div>
          <!-- <div
                @click="goBack"
                class="mt-3"
                style="font-size: 14px;margin-top: 35px;text-align: center;text-decoration: underline;color: #9495E2;cursor: pointer;"
            >
              Back to cart
            </div> -->
        </div>
      </div>
    </div>
    <div>
      <button
        style="display: none"
        type="button"
        class="btn-timeout-order-modal"
        data-bs-toggle="modal"
        data-bs-target="#modalTimeOutOrder"
      ></button>
      <div
        class="modal fade"
        id="modalTimeOutOrder"
        tabindex="-1"
        aria-labelledby="modalTimeOutOrder"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body">
              <div>Please contact staff</div>
            </div>
            <div class="modal-footer">
              <button
                @click="closedModalOrderFail()"
                type="button"
                class="btn btn-sm"
                style="
                  color: white !important;
                  background: var(--color) !important;
                  border-radius: 10px !important;
                "
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="position-fixed top-1 end-1 z-index-2 message-success">
      <material-snackbar
        v-if="snackbar === 'danger' || snackbar === 'success'"
        title=""
        date=""
        :description="message"
        :icon="{ component: 'campaign', color: 'white' }"
        :color="snackbar"
        :close-handler="closeSnackbar"
      />
    </div>
    <material-loading :active="loaderActive" />
  </div>
</template>

<script>
import MaterialAlert from "@/components/MaterialAlert.vue";
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import MaterialLoading from "@/components/MaterialLoading.vue";
import OrderService from "../services/order.service";
import moment from "moment";
import $ from "jquery";
import { localStorageName } from '@/config/utils';

export default {
  name: "Payment-success",
  components: {
    MaterialAlert,
    MaterialSnackbar,
    MaterialLoading,
  },
  data() {
    return {
      payment_order_voucher_discount_total: 0,
      payment_order_total: 0,
      payment_order_final_total: 0,
      message: "",
      loaderActive: false,
      snackbar: null,
      error: "",
      paymentMethod: "",
      branch: JSON.parse(localStorage.getItem(localStorageName('BRANCH_INFO'), '')),
      cutleryValue: 1,
      cutleryChecked: true,
      memberInfo: JSON.parse(localStorage.getItem(localStorageName('memberInfo'), '')),
      memberId: null,
      memberPhone: null,
      memberName: null,
      showErrorName: false,
      showErrorPhone: false,
      subTotal: "s$40.00",
      gstTotal: "s$0.00",
      total: "s$40.00",
      totalToPay: 40,
      itemSelect: [],
      payment_order_id: this.$route.params.order_id ?? null,
      payment_order_gst_total: 0,
      payment_order_service_charge_total: 0,
      status: 0,
      created_at: null,
      latestUpdateTime: moment().format("DD/MM//YYYY hh:mm A"),
      orderDetail: null,
      cartName: "ORDER_INFO",
      merchantCode: "",
      branchCode: "",
      pathType: "",
      tableCode: "",
      intervalId: null,
    };
  },
  async mounted() {
    this.cartName = await this.$store.dispatch("cartName");

    this.showLoader();
    await this.getOrderByOrderId();
    this.hideLoader();
  },
  created() {
    this.merchantCode = this.$store.state.merchantCode;
    this.branchCode = this.$store.state.branchCode;
    this.pathType = this.$route.params.type;
    this.tableCode = this.$route.params.table_code;
    var memberInfoData = JSON.parse(localStorage.getItem(localStorageName('memberInfo'), ''));

    if (memberInfoData) {
      this.memberId = memberInfoData.id ?? 0;
      this.memberName = memberInfoData.name;
      this.memberPhone =
        "+" + memberInfoData.phone_code + memberInfoData.phone_number;
    }
  },
  computed: {
    styleObject() {
      return {
          "--color": this.$store.state.color,
      };
    },
  },
  methods: {
    closeSnackbar() {
      this.snackbar = null;
    },
    selectPaymentMethod(paymentMethod) {
      this.paymentMethod = paymentMethod;
    },
    goBack() {
      this.$router.push({
        name: "Order",
        params: {
          merchant_code: this.merchantCode,
          type: this.pathType,
          branch_code: this.branchCode,
          table_code: this.tableCode,
        },
      });
    },
    async getOrderByOrderId() {
      this.latestUpdateTime = moment().format("DD/MM/YYYY hh:mm A");
      const order_id = this.$route.params.order_id ?? null;

      if (!order_id) {
        this.gotoPath("Order");
      }

      // this.loaderActive = true;
      var dataForm = {
        member_id: this.memberId,
        transaction_id: order_id,
        branch_code: this.branchCode,
      };

      await OrderService.getOrderByOrderId(dataForm).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.orderDetail = response.data.data;
            this.itemSelect = response.data.data.items_ordering_data;
            this.payment_order_voucher_discount_total =
              response.data.data.discount_value;
            // if(response.data.data.payment_method == "MINTY WALLET") {
            //     this.payment_order_final_total = response.data.data.amount_direct_deduct;
            // } else {
            //     this.payment_order_final_total = response.data.data.payment_amount;
            // }
            // const numericValue = parseFloat(this.branch.gst_rate);
            // if(numericValue < 0) { // exclusive
            //     this.payment_order_gst_total = response.data.data.gst_amount;
            //     this.payment_order_total = response.data.data.total_order;
            // } else { // inclusive
            //     this.payment_order_total = response.data.data.payment_amount;
            //     this.payment_order_gst_total = 0
            // }
            this.payment_order_total = response.data.data.total_order;
            this.payment_order_gst_total = response.data.data.gst_amount;
            this.payment_order_service_charge_total =
              response.data.data.service_charge_amount;

            this.payment_order_final_total = response.data.data.total_amount;

            if (
              response.data.data.is_pushed_to_megapos_database &&
              response.data.data.is_pushed_to_megapos_database == 1
            ) {
              this.status = 1;
            } else if (
              response.data.data.is_pushed_to_megapos_database &&
              response.data.data.is_pushed_to_megapos_database == 2
            ) {
              this.status = 2;
            } else {
              this.status = 0;
            }
            this.created_at = response.data.data.created_at;
            this.branch.waiting_time_text = this.orderDetail.watting_time;
          } else {
            // var url =
            //   location.protocol +
            //   "//" +
            //   location.host +
            //   "/" +
            //   merchantCode +
            //   "/" +
            //   branchCode +
            //   "/order";
            // window.location.href = url;
          }
          // this.loaderActive = false;
        },
        (error) => {
          this.message = error;
          // var url =
          //   location.protocol +
          //   "//" +
          //   location.host +
          //   "/" +
          //   merchantCode +
          //   "/" +
          //   branchCode +
          //   "/order";
          // window.location.href = url;
          // this.loaderActive = false;
        }
      );
    },
    showLoader() {
      this.loaderActive = true;
    },
    hideLoader() {
      this.loaderActive = false;
    },
    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },
    closedModalOrderFail() {
      location.reload();
    },
    gotoPath(name) {
      this.$router.push({
        name: name,
        params: {
          merchant_code: this.merchantCode,
          type: this.pathType,
          branch_code: this.branchCode,
          table_code: this.tableCode,
        },
      });
    },
    async handleRetry() {
      this.showLoader();
      const order_id = this.orderDetail.id ?? 0;
      const transaction_id = this.orderDetail.transaction_id ?? "";
      var dataForm = {
        transaction_id: order_id,
      };
      await OrderService.retryOrder(dataForm).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.checkOrderStatus(transaction_id);

            setTimeout(() => {
              clearInterval(this.intervalId);
              this.hideLoader();
              $(".btn-timeout-order-modal").click();
            }, 60000);
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
            this.hideLoader();
          }
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.setTimeOutNotification();
          this.hideLoader();
        }
      );
    },
    checkOrderStatus(transaction_id) {
      var dataForm = {
        member_id: this.memberId,
        transaction_id: transaction_id,
        branch_code: this.branchCode,
      };

      this.intervalId = setInterval(() => {
        OrderService.getOrderStatus(dataForm).then(
          (response) => {
            if (response.data.result.isSuccess) {
              if (response.data.data.status == 3) {
                //SEATED
                clearInterval(this.intervalId);
                this.snackbar = "success";
                this.message = "Retry successfully!";
                this.setTimeOutNotification();
                let url =
                  location.protocol +
                  "//" +
                  location.host +
                  "/" +
                  this.merchantCode +
                  "/QR/" +
                  this.branchCode +
                  "/" +
                  this.tableCode +
                  "/payment-success/" +
                  transaction_id;

                setTimeout(() => {
                  this.hideLoader();
                  window.location.href = url;
                }, 1000);
              }
            }
          },
          (error) => {
            this.message = error;
          }
        );
      }, 3000);
    },
  },
};
</script>

<style scoped>
.page-header {
  padding-bottom: 0 !important;
}

.status-active {
  margin-top: 5px !important;
  color: var(--color);
}
</style>
