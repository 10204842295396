<template>
  <div class="container-custom" :style="styleObject">
    <header>
      <div
        class="d-flex justify-content-between align-items-center"
        style="background: var(--color)"
      >
        <div
          class="d-flex align-items-center ms-3"
          style="width: 20%; cursor: pointer"
          @click="goBack()"
        >
          <span
            class="material-icons color-green md-30"
            style="color: black !important"
            >arrow_back</span
          >
        </div>
        <div class="title-reward-page" style="font-weight: bold !important;">My Reward</div>
        <div
          class="d-flex align-items-center justify-content-center"
          style="width: 20%"
        >
          <!-- <a @click="openPopupReward">
            <img
              src="../assets/img/collect-reward.png"
              class="avatar avatar-md border-radius-lg"
              alt="user1"
            />
          </a> -->
          <a>
            <img
              src="../assets/img/collect-reward.png"
              class="avatar avatar-md border-radius-lg"
              alt="user1"
            />
          </a>
        </div>
      </div>
    </header>
    <div class="content-custom">
      <div v-if="isLoadDone">
        <div v-if="isLogined">
          <div v-if="isOrderPayLater" class="mt-3">
            <div
              class="d-flex justify-content-center p-5 text-center"
              style="background-color: #f0f2f5"
            >
              Rewards are not allowed use for paylater
            </div>
          </div>
          <div v-else>
            <search-input
              :onChangesearchKeyword="onChangesearchKeyword"
              placeholder="Find your reward"
              class="mt-3"
            />
            <div class="mt-3 px-2" v-if="promocodeData.length">
              <div
                v-for="(item, index) in promocodeData"
                :key="index"
                class="reward-card mb-2"
              >
                <div class="row">
                  <div
                    class="col-4"
                    :style="{
                      opacity:
                        item.isCanUseThisBranch && !item.isVoucherLocked ? 1 : 0.5,
                    }"
                  >
                    <img
                      :src="`${item.icon_url}`"
                      style="width: 100px; height: 100px; border-radius: 10px"
                    />
                  </div>
                  <div class="col-8 ps-0 reward-info">
                    <div
                      class="text-bold-400"
                      style="font-size: 15px; width: 80%"
                      :style="{
                        opacity:
                          item.isCanUseThisBranch && !item.isVoucherLocked
                            ? 1
                            : 0.5,
                      }"
                    >
                      {{ item.reward_title ?? "" }}
                    </div>
                    <div
                      class="text-bold mt-1"
                      style="font-size: 10px; font-style: italic"
                      v-if="item.bundle_name"
                    >
                      Bundle: {{ item.bundle_name }}
                    </div>
                    <div
                      class="text-bold-400 mt-1"
                      style="font-size: 10px"
                      v-if="item.order_total"
                      :style="{
                        opacity:
                          item.isCanUseThisBranch && !item.isVoucherLocked
                            ? 1
                            : 0.5,
                      }"
                    >
                      Minimum spend s${{ item.order_total }}
                    </div>
                    <!-- <div
                      class="progress-bar"
                      :style="{
                        background: `linear-gradient(90deg, red ${percentageUsed(
                          item.reward_quantity,
                          item.quantity_exchanged
                        ).toFixed(2)}%, gray ${percentageUsed(
                          item.reward_quantity,
                          item.quantity_exchanged
                        ).toFixed(2)}%)`,
                      }"
                      style="
                        margin-top: 5px;
                        margin-bottom: 3px;
                        height: 4px;
                        width: 80%;
                      "
                    ></div>
                    <div
                      class="text-bold-400"
                      style="font-size: 10px; color: #e55374; width: 80%"
                    >
                      Redeemed
                      {{
                        percentageUsed(
                          item.reward_quantity,
                          item.quantity_exchanged
                        ).toFixed(2)
                      }}%, {{ getFormattedExpirationDate(item.expiry_date_text) }}
                    </div> -->
                    <div
                      class="d-flex align-items-center w-100 justify-content-between pr-3"
                      style="position: absolute; bottom: 0"
                    >
                      <!-- <div style="font-size: 10px; color: white;" class="me-2">
                        Share with friends and earn extra vouchers!
                      </div> -->
                      <div>
                        <div
                          v-if="item.terms_conditions"
                          @click="openTermAndConditions(item.terms_conditions)"
                          class="text-bold-400"
                          style="
                            cursor: pointer;
                            font-size: 10px;
                            text-decoration: underline;
                            color: #1a73e8;
                            text-align: right;
                          "
                        >
                          Term & Conditions
                        </div>
                      </div>
                      <!-- <div v-if="item.isCanUseThisBranch && !item.isVoucherLocked">
                        <div
                          @click="handleRadioClick(null)"
                          v-if="
                            selectedReward &&
                            selectedReward.voucher_id == item.voucher_id
                          "
                          class="btn-remove"
                        >
                          Remove
                        </div>
                        <div
                          @click="handleRadioClick(item)"
                          v-else
                          class="btn-apply"
                        >
                          Apply
                        </div>
                      </div>
                      <div v-else>
                        <div
                          @click="handleRadioClick(null)"
                          v-if="
                            selectedReward &&
                            selectedReward.voucher_id == item.voucher_id
                          "
                          class="btn-remove"
                        >
                          Remove
                        </div>
                        <div
                          class="btn-apply"
                          style="background-color: transparent !important;border: 1px solid var(--color) !important;color: var(--color) !important;"
                          v-else
                        >
                          Apply
                        </div>
                      </div> -->
                    </div>
                  </div>
                </div>
                <!-- <div
                  v-if="item.terms_conditions"
                  @click="openTermAndConditions(item.terms_conditions)"
                  class="text-bold-400"
                  style="
                    cursor: pointer;
                    font-size: 10px;
                    text-decoration: underline;
                    color: #1a73e8;
                    margin-top: 5px;
                    text-align: right;
                  "
                >
                  Term & Conditions
                </div> -->
              </div>
            </div>
            <div v-else>
              <div
                class="d-flex justify-content-center p-5"
                style="background-color: #f0f2f5"
              >
                No rewards available
              </div>
            </div>
          </div>
        </div>
        <div class="custom-link text-center mt-3 px-2" v-else>
          <span @click="gotoSignIn()">Log in</span> or
          <span @click="gotoSignUp()">Sign up</span>
        </div>
      </div>
    </div>
    <footer v-if="!fromCart">
      <Footer></Footer>
      <!-- <VoucherAvailable></VoucherAvailable> -->
    </footer>
    <div class="position-fixed top-1 end-1 z-index-2 message-success">
      <material-snackbar
        v-if="snackbar === 'danger' || snackbar === 'success'"
        title=""
        date=""
        :description="message"
        :icon="{ component: 'campaign', color: 'white' }"
        :color="snackbar"
        :close-handler="closeSnackbar"
      />
    </div>
    <div>
      <button
        style="display: none"
        type="button"
        class="btn-term-and-conditions-modal"
        data-bs-toggle="modal"
        data-bs-target="#modalShowTermAndConditions"
      ></button>
      <div
        class="modal fade"
        id="modalShowTermAndConditions"
        tabindex="-1"
        aria-labelledby="modalShowTermAndConditionsLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body">
              <div v-html="contentTermAndCondition" style="white-space: pre-line;"></div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-primary btn-close-modal btn-sm mb-0"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <material-loading :active="loaderActive" />
</template>

<script>
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import MaterialLoading from "@/components/MaterialLoading.vue";
import PromocodeService from "../services/promocode.service";
import moment from "moment";
import $ from "jquery";
// import VoucherAvailable from "@/common/VoucherAvailable/VoucherAvailable";
import Footer from "@/common/Footer/OrderFooter";
import SearchInput from "@/components/SearchInput.vue";
import { gotoMemberLogin, localStorageName } from "@/config/utils"

export default {
  name: "sign-in",
  components: {
    MaterialSnackbar,
    MaterialLoading,
    // VoucherAvailable,
    Footer,
    SearchInput,
  },
  data() {
    return {
      isLoadDone: false,
      snackbar: null,
      branch: JSON.parse(localStorage.getItem(localStorageName('BRANCH_INFO'), '')),
      cutleryValue: 1,
      memberInfo: JSON.parse(localStorage.getItem(localStorageName('memberInfo'), '')),
      loaderActive: false,
      searchKeyword: "",
      progressPercentage: 10, // Set the initial percentage here
      progressBarColor: `linear-gradient(90deg, red 50%, gray 50%)`,
      contentTermAndCondition: "",
      countSelectedCashbackReward: 0,
      countTotalSelectedReward: 0,
      selectedReward: [],
      promocodeData: [],
      showMessageLimitSelectedReward: "",
      message: "",
      searchTimerId: null,
      fromCart: this.$route.params.from_cart ?? false,
      isLogined: false,
      isOrderPayLater: false,
    };
  },
  watch: {
    progressPercentage() {
      this.updateProgressBarColor();
    },
  },
  async created() {
    const merchantCode = this.$store.state.merchantCode;
    this.merchantCode = merchantCode ? `/${merchantCode}` : "";
    this.branchCode = this.$store.state.branchCode;
    var memberInfoData = JSON.parse(localStorage.getItem(localStorageName('memberInfo'), ''));
    this.tableCode = this.$route.params.table_code;
    this.tableInfo = JSON.parse(localStorage.getItem('table_' + merchantCode.toLowerCase() + '_' + this.branchCode.toLowerCase() + '_' + this.tableCode.toLowerCase(), null));

    if (this.tableInfo) {
      if (!this.tableInfo.stripe_connect) {
        this.isOrderPayLater = true;
      }
    }

    if (memberInfoData) {
      this.isLogined = true;
      this.isLoadDone = false;
      if (!this.isOrderPayLater) {
        await this.getList();
      }
      this.isLoadDone = true;
    } else {
      this.isLogined = false;
      this.isLoadDone = true;
    }
  },
  computed: {
    styleObject() {
      return {
        "--color": this.$store.state.color,
      };
    },
  },
  methods: {
    closeSnackbar() {
      this.snackbar = null;
    },
    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },
    showLoader() {
      this.loaderActive = true;
    },
    hideLoader() {
      this.loaderActive = false;
    },
    updateProgressBarColor() {
      this.progressBarColor = `linear-gradient(90deg, red ${this.progressPercentage}%, gray ${this.progressPercentage}%)`;
    },
    goBack() {
      this.$router.go(-1);
    },
    gotoSignIn() {
      gotoMemberLogin('myreward');
      // window.open(process.env.VUE_APP_MEMBER_SITE_URL + this.merchantCode + "/sign-in?branch_code=" + this.branchCode + "&from=myreward","_self");
    },
    gotoSignUp() {
      gotoMemberLogin('myreward');
      // window.open(
      //   process.env.VUE_APP_MEMBER_SITE_URL +
      //     this.merchantCode +
      //     "/sign-in?branch_code=" +
      //     this.branchCode + "&from=myreward",
      //   "_self"
      // );
    },
    onChangesearchKeyword(event) {
      this.searchKeyword = event.target.value;
      clearTimeout(this.searchTimerId);
      this.searchTimerId = setTimeout(() => {
        this.getList();
      }, 1000);
    },
    async getList() {
      this.showLoader();

      let dataForm = {
        search_keyword: this.searchKeyword,
        type: "",
        merchant_id: localStorage.getItem(localStorageName('MERCHANT_ID'), 0),
        branch_code: this.branch.branch_code,
      };

      await PromocodeService.getMyRewardOrderingOnline(dataForm).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.promocodeData = response.data.data;

            const storedReward = localStorage.getItem(localStorageName('SELECTED_REWARD'));
            if (storedReward) {
              var storedRewardConveted = JSON.parse(storedReward);
              if (storedRewardConveted && storedRewardConveted.length > 0) {
                this.selectedReward = storedRewardConveted[0];
                this.countSelectedCashbackReward = 1;
                this.countTotalSelectedReward = 1;
              }
            }
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
        }
      );
    },
    getFormattedExpirationDate(expirationDate) {
      const now = moment();
      const expiration = moment(expirationDate);
      const daysRemaining = expiration.diff(now, "days");

      if (daysRemaining === 0) {
        return "expired today";
      } else if (daysRemaining === 1) {
        return "expires in 1 day";
      } else {
        return `expires in ${daysRemaining} days`;
      }
    },
    openTermAndConditions(content) {
      this.contentTermAndCondition = content;
      $(".btn-term-and-conditions-modal").click();
    },
    percentageUsed(total, used) {
      return (used / total) * 100;
    },
    handleUseVoucher() {
      this.countSelectedCashbackReward = 0;
      this.countTotalSelectedReward = 0;
      localStorage.removeItem(localStorageName('SELECTED_REWARD'));
      if (this.selectedReward) {
        this.showMessageLimitSelectedReward = "";
        localStorage.setItem(
          localStorageName('SELECTED_REWARD'),
          JSON.stringify([this.selectedReward])
        );
        this.countSelectedCashbackReward = 1;
        this.countTotalSelectedReward = 1;
        this.$router.go(-1);
      } else {
        this.$router.go(-1);
      }
    },
    handleRadioClick(item) {
      if (item) {
        this.selectedReward = item;
        this.countSelectedCashbackReward = 1;
        this.countTotalSelectedReward = 1;
      } else {
        this.selectedReward = null;
        this.countSelectedCashbackReward = 0;
        this.countTotalSelectedReward = 0;
      }
      this.handleUseVoucher();
    },
    // openPopupReward() {
    //   $(".btn-show-voucher-available-modal").click();
    // },
  },
};
</script>
<style>
.title-reward-page {
  width: 60%;
  color: black;
  text-align: center;
}
.reward-card {
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 5px 10px;
}
.reward-info {
  position: relative;
}
.btn-apply,
.btn-remove {
  font-size: 14px;
  color: white;
  border-radius: 10px;
  background-color: var(--color);
  padding: 5px 15px;
  margin-right: 12px;
}
.btn-remove {
  background-color: orangered;
}
.text-bold-500 {
  color: black;
  font-weight: 500;
}
.text-bold-400 {
  color: black;
  font-weight: 400;
}
.progress-bar {
  height: 20px;
  width: 100%;
}
.container-custom {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.content-custom {
  flex: 1;
  background-color: white;
  overflow: auto;
}
footer {
  background-color: white;
  padding-top: 50px;
}
.custom-link span {
  text-decoration: underline;
  color: #1a73e8 !important;
  cursor: pointer;
}
</style>
