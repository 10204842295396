<template>
  <!-- <div class="page-header align-items-start min-vh-100" v-if="isWaitingTime">
    <div class="merchant-logo"> -->
      <!-- <img
        src="@/assets/img/logos/WH_Logo.gif"
        class="avatar-md border-radius-lg"
        alt="logo"
        height="240"
      /> -->
    <!-- </div>
    <div class="container p-0">
      <div class="row justify-content-center">
        <div class="text-center">
          <div
            class="font-weight-bolder outlet-name"
          >
            {{ branch.branch_name }}
          </div>
          <div class="estimated-waiting-time">
            Estimated Waiting Time
          </div>
          <div class="estimated-waiting-time">
            {{ branch.waiting_time_text }}
          </div>
        </div>
        <material-button class="btn btn-start-ordering" @click="gotoSlide()">START ORDERING</material-button>
      </div>
    </div>
  </div> -->
  <div v-if="!loaderActive && (branch && branch.show_landing_page_on_ordering_online)" class="page-header align-items-start min-vh-100" :style="styleObject" @click="gotoCategory()">
    <swiper
      :slides-per-view="1"
      :space-between="0"
      :loop="true"
      :autoplay="{
        delay: 3000,
        disableOnInteraction: false,
      }"
      :pagination="{
        clickable: true,
        el: '.swiper-pagination',
      }"
    >
      <swiper-slide v-for="(value, index) in slidesLandingPage" :key="index">
        <div class="slider">
          <img
            :src=value.file_url
            alt="error"
          />
        </div>
      </swiper-slide>
      <!-- <swiper-slide>
        <div class="slider">
          <img
            src="@/assets/img/cover-image-1.png"
            alt="error"
          />
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="slider">
          <img
            src="@/assets/img/cover-image-2.png"
            alt="error"
          />
        </div>
      </swiper-slide> -->
    </swiper>
    <div class="swiper-pagination"></div>
    <div class="order-now">
      <material-button>Order now</material-button>
    </div>
  </div>
  <VoucherAvailable />
  <material-loading :active="loaderActive" />
</template>

<script>
import { mapMutations } from "vuex";
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, { Pagination } from "swiper";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import MaterialButton from "@/components/MaterialButton.vue";
import router from "@/router";
import CryptoJS from 'crypto-js';
import TokenService from "../services/token.service";
import AuthService from "../services/auth.service";
import VoucherAvailable from "@/common/VoucherAvailable/VoucherAvailable";
// import $ from "jquery";
import MaterialLoading from '@/components/MaterialLoading.vue';
import OrderService from "../services/order.service";
import { localStorageName } from '@/config/utils';

SwiperCore.use([Pagination]);

export default {
  name: "Cover",
  components: {
    Swiper,
    SwiperSlide,
    MaterialButton,
    VoucherAvailable,
    MaterialLoading
  },
  data() {
    return {
      loaderActive: false,
      message: "",
      snackbar: null,
      isWaitingTime: true,
      prefixUrl: "",
      branch: JSON.parse(localStorage.getItem(localStorageName('BRANCH_INFO'), '')),
      merchantCode: "",
      branchCode: "",
      slidesLandingPage: [],
      memberInfo: JSON.parse(localStorage.getItem(localStorageName('memberInfo'), '')),
    };
  },
  beforeMount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  async created() {
    this.prefixUrl = await this.$store.dispatch('prefixUrl', this.$route.params);
    const token = this.$route.query.token ?? null;
    const key = this.$route.query.key ?? null;
    const tokenLocal = TokenService.getLocalAccessToken();

    if(!key && !token && this.branch.show_landing_page_on_ordering_online == 0) {
      router.push(this.prefixUrl + "/order");
    }

    if(key) {
      if(tokenLocal) {
        router.push(this.prefixUrl + "/category");
      } else {
        this.handleLoginByPhoneAndPasscode(key);        
      }
    }

    if (token) {
      this.handleLoginToken(token);
    }
  },
  mounted() {
    this.merchantCode = this.$store.state.merchantCode;
    this.branchCode = this.$store.state.branchCode;
    setTimeout(() => {
      this.isWaitingTime = false;
    }, 3000);
    const key = this.$route.query.key ?? null;
    if(!key) {
      // $(".btn-show-voucher-available-modal").click();
    }
    if (this.merchantCode && this.branchCode) {
      this.getSlides();
    }
  },
  computed: {
    styleObject() {
      return {
        "--color": this.$store.state.color,
      };
    },
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),

    gotoSlide() {
      this.isWaitingTime = false;
    },

    gotoCategory() {
      this.$router.push(this.prefixUrl + "/category");
    },

    handleLoginToken(token) {
      var _this = this;
      _this.closeSnackbar();
      _this.showLoader();
      _this.$store.dispatch("auth/loginToken", token).then(
        (data) => {
          if (data != undefined && !data.result.isSuccess) {
            this.hideLoader();
            _this.message = data.result.message;
            _this.showSnackbarError();
          } else {
            router.push(this.prefixUrl);
          }
          _this.hideLoader();
        },
        (error) => {
          console.log("ERROR" + error);
        }
      );
    },

    closeSnackbar() {
      this.snackbar = null;
    },

    showLoader() {
      this.loaderActive = true;
    },

    hideLoader() {
      this.loaderActive = false;
    },

    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },

    showSnackbarError() {
      this.snackbar = "danger";
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },

    handleLoginByPhoneAndPasscode(data) {
      this.showLoader();
      const from = this.$route.query.from ?? "";
      var token = data;
      const memberId = this.$route.query.memberId ?? null;

      let dataForm = {
        memberId: memberId
      };

      AuthService.getMemberInfo(dataForm).then(
          (response) => {
              if (response.result.isSuccess) {
                localStorage.setItem(localStorageName('token'), token);
                localStorage.setItem(localStorageName('memberInfo'), JSON.stringify(response.data.user));
                localStorage.removeItem(localStorageName('ORDER_ID_NON_MEMBER'));
                if(from && from == "myreward") {
                  router.push(this.prefixUrl + "/my-reward");
                } else if (from && from == "mycart") {
                  router.push(this.prefixUrl + "/my-cart");
                } else if (from && from == "profile") {
                  router.push(this.prefixUrl + "/profile");
                } else if (from && from == "order") {
                  router.push(this.prefixUrl + "/order");
                } else {
                  router.push(this.prefixUrl + "/category");
                }
              } else {
                  this.snackbar = "danger";
                  this.message = response.result.message;
                  this.setTimeOutNotification();
                  this.hideLoader();
              }
              this.isLoadDone = true;
          },
          (error) => {
              this.snackbar = "danger";
              this.message = error;
              this.hideLoader();
          }
      );
      // var _this = this;
      // _this.closeSnackbar();
      // _this.showLoader();
      // _this.$store.dispatch("auth/loginToken", token).then(
      //   (data) => {
      //     if (data != undefined && !data.result.isSuccess) {
      //       this.hideLoader();
      //       _this.message = data.result.message;
      //       _this.showSnackbarError();
      //     } else {
      //       localStorage.setItem("token", token);
      //       localStorage.setItem("memberInfo", JSON.stringify(data.data.user));
      //       router.push(this.merchantCode + this.branchCode+"/order");
      //     }
      //     _this.hideLoader();
      //   },
      //   (error) => {
      //     console.log("ERROR" + error);
      //   }
      // );

      
      // var _this = this;
      // _this.closeSnackbar();
      // _this.showLoader();
      // _this.$store.dispatch("auth/loginToken", token).then(
      //   (data) => {
      //     if (data != undefined && !data.result.isSuccess) {
      //       this.hideLoader();
      //       _this.message = data.result.message;
      //       _this.showSnackbarError();
      //     } else {
      //       localStorage.setItem("token", token);
      //       localStorage.setItem("memberInfo", JSON.stringify(data.data.user));
      //       router.push(this.merchantCode + this.branchCode+"/order");
      //     }
      //     _this.hideLoader();
      //   },
      //   (error) => {
      //     console.log("ERROR" + error);
      //   }
      // );


      // let result = JSON.parse(this.decryptData(data));
      // var _this = this;
      // _this.closeSnackbar();
      // _this.showLoader();
      // _this.$store.dispatch("auth/loginByPhoneAndPasscode", result).then(
      //   (data) => {
      //     if (data != undefined && !data.result.isSuccess) {
      //       this.hideLoader();
      //       _this.message = data.result.message;
      //       _this.showSnackbarError();
      //     } else {
      //       router.push(this.merchantCode + this.branchCode+"/order");
      //     }
      //     _this.hideLoader();
      //   },
      //   (error) => {
      //     console.log("ERROR" + error);
      //   }
      // );
    },

    decryptData(encryptedData) {
        if (encryptedData) {
            try {
                const decryptedData = CryptoJS.AES.decrypt(atob(encryptedData), process.env.VUE_APP_KEY_DECRYPT_MEMBER_INFORMATION).toString(CryptoJS.enc.Utf8);
                return decryptedData;
            } catch (error) {
                return "";
            }
        }
    },

    getSlides() {
      // if(this.memberInfo) {
        // console.log(this.memberInfo);
        this.showLoader();
        var dataForm = {
          isMintyApi: true,
          branch_code: this.branchCode,
          where_showing: 4,
          is_ordering_online: 1
        }
        OrderService.getSlidesLandingPage(dataForm).then(
          (response) => {
            if (response.data.result.isSuccess) {
              this.slidesLandingPage = response.data.data
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
            }
            this.isLoadDone = true;
            this.hideLoader();
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.hideLoader();
          }
        );
      // }
    },
  },
};
</script>

<style scoped>
.page-header {
  position: relative;
  background-color: var(--color);
  padding-bottom: 0 !important;
}

.page-header .merchant-logo {
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 45%;
  transform: translate(0, -50%);
  z-index: 1;
}

.page-header .container {
  color: #222 !important;
  margin-top: calc(100vh - 300px);
}

.page-header .container .outlet-name {
  font-size: 28px;
}

.page-header .order-now {
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 80%;
  transform: translate(0, -50%);
  z-index: 1;
}

.page-header .order-now .btn {
  background: #ffffff;
  color: #222;
  border-radius: 5px;
  padding: 7px 50px;
  font-size: 12px;
  text-transform: unset !important;
}

.btn-start-ordering {
  width: 300px;
  margin-top: 12vh;
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  background: none !important;
  border: 1px solid #222 !important;
  box-shadow: none !important;
  border-radius: unset !important;
}

.estimated-waiting-time {
  font-size: 20px;
  font-weight: bold;
  line-height: 24px;
}

.slider,
.slider img {
  width: 100%;
  height: 100vh;
}
</style>
