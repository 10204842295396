<template>
  <div>
    <button
      style="display: none"
      type="button"
      class="btn-choose-number-of-guests-modal"
      data-bs-toggle="modal"
      data-bs-target="#modalChooseNumberOfGuests"
    ></button>
    <button
      style="display: none"
      type="button"
      class="btn-close-choose-number-of-guests-modal"
      data-bs-target="#modalChooseNumberOfGuests"
      data-bs-dismiss="modal"
    ></button>
    <div
      class="modal fade"
      id="modalChooseNumberOfGuests"
      tabindex="-1"
      aria-labelledby="modalChooseNumberOfGuestsLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <div class="mt-3 px-3">
              <div style="color: var(--color); font-size: 18px">
                Choose number of guests
              </div>
              <div class="number-guest-list mt-3 d-flex">
                <div
                  v-for="index in numberOfGuests"
                  :key="index"
                  :class="[
                    'number-guest-box',
                    { active: numberOfGuestsSelected == index },
                  ]"
                  :id="index + '-guest'"
                  @click="selectNumberOfGuests(index)"
                >
                  {{ index }}
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn-submit"
              @click="chooseNumberOfGuests()"
            >
              Proceed
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import OrderService from "../services/order.service";

export default {
  name: "ModalChooseNumberOfGuests",
  props: {
    branchCode: {
      type: String,
      required: true,
    },
    tableCode: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      numberOfGuests: 10,
      numberOfGuestsSelected: 0,
    };
  },
  methods: {
    selectNumberOfGuests(index) {
      $(".number-guest-box").removeClass("active");
      $("#" + index + "-guest").addClass("active");
      this.numberOfGuestsSelected = index;
    },
    chooseNumberOfGuests() {
      let dataForm = {
        branch_code: this.branchCode,
        table_code: this.tableCode,
        number_of_guests: this.numberOfGuestsSelected,
      };

      OrderService.chooseNumberOfGuests(dataForm).then(
        (response) => {
          if (response.data.result.isSuccess) {
            $(".btn-close-choose-number-of-guests-modal").click();
          } else {
            console.log(response.data.result.message);
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
  },
};
</script>

<style scoped>
.btn-submit {
  padding: 5px 30px;
  border-radius: 0;
  background-color: var(--color);
  color: #fff !important;
  border: 1px solid var(--color);
  background-color: var(--color);
  border-radius: 5px;
}

.number-guest-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.number-guest-list > .number-guest-box {
  text-align: center;
  border: 1px solid var(--color);
  color: var(--color);
  flex: 1 0 15%;
  max-width: 18% !important;
  padding: 10px;
  font-size: 18px;
  font-weight: bold;
  margin: 10px calc(10% / 5);
}

.number-guest-list > .number-guest-box.active {
  background: var(--color);
  color: #fff !important;
}
</style>
